import React, { forwardRef, useState } from 'react';
import { InputAdornment, IconButton, TextField } from '@mui/material';
import { Icon } from 'components/icon';
import { ENUM_ICON } from 'enums/icons';

import type { PasswordTextFieldProps } from './types';

function PasswordTextField({ error, helperText, ...props }: PasswordTextFieldProps, ref: React.Ref<HTMLDivElement>) {
   const [isVisible, setIsVisible] = useState(false);

   return (
      <TextField
         {...props}
         sx={{ width: { xs: '100%', sm: '346px' }, marginX: 'auto' }}
         ref={ref}
         className='CommonInput'
         autoComplete='off'
         error={error}
         type={isVisible ? 'text' : 'password'}
         helperText={error ? helperText : null}
         InputProps={{
            endAdornment: (
               <InputAdornment position='end'>
                  <IconButton disableRipple onClick={() => setIsVisible((prev) => !prev)} sx={{ p: 0 }}>
                     <Icon name={!isVisible ? ENUM_ICON.HIDDEN : ENUM_ICON.SHOW} />
                  </IconButton>
               </InputAdornment>
            ),
         }}
      />
   );
}

export default forwardRef(PasswordTextField);
