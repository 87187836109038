import { Box, Typography } from '@mui/material';
import React, { useEffect, useLayoutEffect } from 'react';
import { IMAGE } from 'enums/images';
import { customOptions } from 'styles/theme';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { creatorsState, getSocialImapactIndicatorForUser } from 'store/creators/creators.slice';

export interface StepComponentProps {
   isMobile: boolean;
   onNext: () => void;
   onPrev?: () => void;
}

export const colors = {
   button: '#B415FF',
} as const;

export function CalculatingStep({ isMobile, onNext, onPrev }: StepComponentProps): React.ReactElement {
   useLayoutEffect(() => {
      setTimeout(() => {
         onNext();
      }, 4000);
   }, []);

   const { calculatedIdicators } = useAppSelector(creatorsState);
   const dispatch = useAppDispatch();

   useEffect(() => {
      if (calculatedIdicators == null) {
         dispatch(getSocialImapactIndicatorForUser());
      }
   }, [calculatedIdicators]);

   return (
      <Box
         sx={{
            height: '100%',
            width: { xs: '100%', sm: '400px' },
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            paddingTop: '120px',
            paddingLeft: '24px',
            paddingRight: '24px',
            margin: `0 -${customOptions.rootSpacing.paddingLeft}`,
            gap: 3.75,
         }}
      >
         <Typography sx={{ fontWeight: 900, textAlign: 'center', lineHeight: '38px' }} variant='h4' component='h1'>
            Hold on, calculating your predicted impact & benefits
         </Typography>
         <Box
            sx={{
               width: { xs: '35vw', sm: '45%' },
               borderRadius: '10px',
            }}
            src={IMAGE.CALCULATING_GIF}
            alt='Calculating'
            component='img'
         />
      </Box>
   );
}
