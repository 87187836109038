import React from 'react';
import { Box, BoxProps, styled, IconButton, IconButtonProps } from '@mui/material';

export const CenteredWrapper = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' gap={2.5} {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   width: '100%',
   textAlign: 'left',
   display: 'flex',
   flexDirection: 'column',
}));

export const ActionItemsWrapper = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   width: '100%',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'space-between',
}));

export const IconWrapper = styled(({ children, ...props }: IconButtonProps) => (
   <IconButton
      component='div'
      sx={{
         transition: 'all 0.25s linear',
         '&:hover': {
            boxShadow: `-1px 2px 0 #222`,
         },
      }}
      {...props}
   >
      {children}
   </IconButton>
))(({ theme }) => ({
   width: theme.typography.pxToRem(48),
   height: theme.typography.pxToRem(48),
   background: theme.palette.black.main,
   border: `1px solid ${theme.palette.white.main}`,
   boxShadow: `-2px 4px 0 ${theme.palette.black.main}`,
   '&:hover': {
      background: theme.palette.black.main,
   },
   '&.MuiIconButton-root.Mui-disabled': {
      opacity: 0.6,
      background: theme.palette.black.main,
   },
}));

export const AmountBox = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   minWidth: '179px',
   maxWidth: '200px',
   // height: '73px',
   background: theme.palette.white.main,
   border: `2px solid ${theme.palette.black.main}`,
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   borderRadius: '8px',
}));
