export const routes = () => ({
   welcome: '/',
   intro: '/intro',
   email: '/email',
   save: '/save',
   password: '/password',
   loginEmail: '/loginEmail',
   loginPassword: '/loginPassword',
   newPassword: '/newPassword',
   resetPassword: '/resetPassword',
   forgotPassword: '/forgotPassword',
   anonymousConnect: '/saveProfile',
   loadingProfile: '/loadingProfile',
   checkYourEmail: '/checkYourEmail',
   signIn: '/signIn',
   share: '/share',
   mobileRedirect: '/mobileredirect',

   gender: '/gender',
   causes: '/causes',
   subscription: '/subscription',
   donation: '/donation',
   portfolio: '/portfolio',
   charity: '/charity/:id',
   name: '/name',
   generatePage: '/generatePortfolio',
   gratitude: '/gratitude',
   profile: '/profile',
   myProfile: '/myProfile',
   impactDetail: '/impactDetail/:slug/:impcatId',
   publicProfile: '/p/:slug',
   magicLink: '/ml',

   creatorFlow: {
      base: '/creators',
      intro: '',
      emailValidation: 'ml/:code',
      creatorImpact: 'impact',
      gratitude: 'gratitude',
   },
});
