import React from 'react';
import { Box, BoxProps, styled } from '@mui/material';

const useGlobalStyle = process.env.REACT_APP_BONO_GLOBAL === 'true';

export const CausesListBox = styled(
   // eslint-disable-next-line react/display-name
   React.forwardRef<HTMLDivElement, BoxProps>(({ children, ...props }, ref) => (
      <Box component='div' gap={2.375} {...props} ref={ref}>
         {children}
      </Box>
   ))
)(({ theme }) => ({
   display: 'grid',
   gridTemplateRows: '1fr 1fr',
   gridAutoFlow: 'column',
   overflowX: 'auto',
   '-ms-overflow-style': 'none',
   scrollbarWidth: 'none',

   '&::-webkit-scrollbar': {
      display: 'none',
   },

   [theme.breakpoints.down('sm')]: {
      overflowX: 'auto',
   },
   paddingBottom: '10px',
   paddingTop: '10px',
   paddingLeft: 6,
   paddingRight: 2,
   gap: '8px',
}));

const bgColorsLocal = ['#E4CCCC', '#D7D8DE', '#D5D4D1', '#D4D9E5', '#CCD9D9', '#E9E0D4'];
const bgColorsGlobal = ['#0279CB', '#D97400', '#2BA096', '#A74D06', '#FF4F44', '#0279CB'];

export const bgColors = useGlobalStyle ? bgColorsGlobal : bgColorsLocal;

type SingleCauseBoxProps = BoxProps & {
   index: number;
   isselected: string;
   color: string;
};

export const SingleCauseBox = styled(({ children, index, ...props }: SingleCauseBoxProps) => (
   <Box component='div' {...props} pl='2px' pr='2px'>
      {children}
   </Box>
))(({ theme, index, isselected, color }) => ({
   userSelect: 'none',
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'start',
   textAlign: 'start',
   justifyContent: 'start',
   position: 'relative',
   fontSize: '16px',
   lineHeight: '18px',
   textTransform: 'capitalize',
   borderRadius: '10px',
   fontWeight: theme.typography.fontWeightMedium,
   gap: `${theme.typography.pxToRem(theme.size.font_4)}`,
   wordBreak: 'break-word',
   // eslint-disable-next-line no-nested-ternary, prettier/prettier
   boxShadow: useGlobalStyle ? 'none' : isselected === 'false' ? `-4px 4px ${theme.palette.black.main}` : `-7px 7px ${theme.palette.black.main}`,
   border: useGlobalStyle ? 'none' : `1px solid ${theme.palette.black.main}`,
   width: '120px',
   height: '120px',
   color: useGlobalStyle ? '#FFFFFFCC' : 'rgba(0, 0, 0, 0.87);',
   padding: 10,
   backgroundColor: color,
   cursor: 'pointer',
}));

export const CheckedCause = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   position: 'absolute',
   bottom: '5px',
   left: '7px',
   background: '#333333',
   width: theme.typography.pxToRem(32),
   height: theme.typography.pxToRem(32),
   border: `2px solid #333333`,
   borderRadius: '50%',
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
}));

export const UnCheckedCause = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   position: 'absolute',
   bottom: '5px',
   left: '7px',
   width: theme.typography.pxToRem(32),
   height: theme.typography.pxToRem(32),
   border: useGlobalStyle ? 'none' : `3px solid ${theme.palette.gray.additional}`,
   background: useGlobalStyle ? 'none' : `#ffffff`,
   borderRadius: '50%',
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
}));
