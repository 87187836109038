import { Box, Button, TextField, Typography } from '@mui/material';

import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addName, authState } from 'store/auth/auth.slice';
import { creatorsState, updateCurrentStep } from 'store/creators/creators.slice';
import { StyledInput } from '../textField/CreatorFlowTextField';

export interface StepComponentProps {
   getHeight: (height: number) => void;
}

export const colors = {
   button: '#B415FF',
} as const;

export function NameStep({ getHeight }: StepComponentProps): React.ReactElement {
   const containerRef = useRef<HTMLDivElement | null>(null);
   const [totalHeight, setTotalHeight] = useState(0);
   const dispatch = useAppDispatch();
   const { currentStep } = useAppSelector(creatorsState);

   useEffect(() => {
      if (containerRef.current) {
         const { height } = containerRef.current.getBoundingClientRect();
         setTotalHeight(height);
         getHeight(height);
      }
   }, []); // Runs once on mount.

   const handleUserNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      dispatch(addName(e.target.value));
   };

   const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === 'Enter') {
         e.preventDefault();
         dispatch(updateCurrentStep(currentStep + 1));
      }
   };

   return (
      <Box
         ref={containerRef}
         sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            paddingTop: '40px',
            gap: 3.75,
         }}
      >
         <Typography sx={{ fontWeight: 900, lineHeight: '38px' }} variant='h4' component='h1'>
            What should we call you today?
         </Typography>
         <Box
            sx={{
               width: '100%',
            }}
         >
            <StyledInput
               onKeyDown={handleKeyDown}
               type='text'
               placeholder='Your Name'
               onChange={handleUserNameChange}
            />
         </Box>
      </Box>
   );
}
