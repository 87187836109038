/* eslint-disable no-console */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { routes } from 'routes/routes';
import { PageWrapper } from 'wrappers';
import { IMAGE } from 'enums/images';
import { PageMainLabel, PageSubLabel } from 'components/common';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { authState, fetchUserByAccessToken } from 'store/auth/auth.slice';
import { LoggedUserDataTypes } from 'store/auth/types';

const textList = ['Welcome Back', 'We are loading your profile', 'Few more seconds...'];

export const MyProfileForward = () => {
   const navigate = useNavigate();
   const generatingProfileTime = Number(process.env.REACT_APP_TEXT_GENERATING_INTERVAL) || 2000;
   const timeBeforeNextPage = generatingProfileTime * textList.length;
   const screenSE = useMediaQuery('(max-height:700px)');
   const { t } = useTranslation();

   const [currentText, setCurrentText] = useState(0);

   const dispatch = useAppDispatch();
   const { loggedUserData } = useAppSelector(authState);
   const userToken = localStorage.getItem('accessToken');

   const afterLoginRedirect = (loggedUser?: LoggedUserDataTypes) => {
      const hasSubscription = loggedUser?.subscription?.status === 'active';
      if (hasSubscription) {
         navigate(routes().publicProfile.replace(':slug', loggedUser?.publicName || ''));
      } else {
         navigate(routes().causes);
      }
   };

   const errorOnLogin = () => {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refeshToken');
      navigate(routes().loginEmail);
   };

   useEffect(() => {
      const fetchData = async () => {
         if (userToken) {
            if (loggedUserData === null || loggedUserData?.subscription == null) {
               dispatch(fetchUserByAccessToken())
                  .then((res) => {
                     if (res.meta.requestStatus !== 'fulfilled') {
                        errorOnLogin();
                     } else {
                        afterLoginRedirect(res.payload);
                     }
                  })
                  .catch((err) => {
                     console.log('err', err);
                     errorOnLogin();
                  });
            } else {
               afterLoginRedirect(loggedUserData);
            }
         }
      };

      fetchData();
   }, [userToken]);

   useEffect(() => {
      const intervalId = setInterval(() => {
         setCurrentText((prevIndex) => (prevIndex + 1) % textList.length);
      }, generatingProfileTime);

      document.title = `Loading... | ${t('siteTitleName')}`;

      return () => clearInterval(intervalId);
   }, []);

   return (
      <PageWrapper
         logo={false}
         sx={{ background: ({ palette }) => palette.main.mainBackground, width: '100vw', height: '100vh' }}
         logoSpace={!screenSE}
      >
         <Box
            component='section'
            display='flex'
            flexDirection='column'
            width='100%'
            paddingBottom='50%'
            mt='110px'
            justifyContent='center'
            maxWidth='390px'
         >
            <Box component='div' width='100%' display='flex' flexDirection='column' textAlign='center'>
               <Box component='div' mb={3.75}>
                  <Box
                     component='img'
                     src={IMAGE.SVG_LOGO}
                     alt='BONO'
                     height='52px'
                     sx={{ width: '77px !important' }}
                  />
               </Box>
               <Box component='div' width='100%' display='flex' flexDirection='column' gap={2.25}>
                  <PageMainLabel sx={{ textTransform: 'none' }}>Loading your profile</PageMainLabel>
                  {textList.map((text, index) =>
                     currentText === index ? (
                        <PageSubLabel
                           key={text}
                           sx={{
                              px: '20px',
                              opacity: index === currentText ? 1 : 0,
                              transform: 'scale(1)',
                              transition: 'opacity 1s, transform 0.1s',
                              animation: 'pulse 3s infinite',
                              '@keyframes pulse': {
                                 '0%': {
                                    transform: 'scale(1)',
                                 },
                                 '50%': {
                                    transform: 'scale(1.05)',
                                 },
                                 '100%': {
                                    transform: 'scale(1)',
                                 },
                              },
                           }}
                        >
                           {text}
                        </PageSubLabel>
                     ) : null
                  )}
               </Box>
            </Box>
         </Box>
      </PageWrapper>
   );
};
