// ModalComponent.tsx

import React from 'react';
import { Modal, Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ModalComponentProps {
   open: boolean;
   onClose: () => void;
   title?: string;
   content: string;
}

const ModalComponent = ({ open, onClose, title, content }: ModalComponentProps) => {
   const { t } = useTranslation();
   return (
      <Modal open={open} onClose={onClose} aria-labelledby='modal-title' aria-describedby='modal-description'>
         <Box
            sx={{
               position: 'absolute',
               top: '50%',
               left: '50%',
               transform: 'translate(-50%, -50%)',
               width: 350,
               bgcolor: 'background.paper',
               px: 4,
               py: 3,
               textAlign: 'center',
               borderRadius: '8px',
               outline: 'none',
            }}
         >
            {title ? (
               <Typography sx={{ mt: 2, textAlign: 'start', fontWeight: '700', fontSize: '22px' }}>{title}</Typography>
            ) : (
               ' '
            )}
            <Typography
               id='modal-description'
               sx={{
                  textAlign: 'start',
                  fontWeight: '500',
                  fontSize: '16px',
                  lineHeight: '18px',
                  mt: '8px',
                  mb: '10px',
               }}
            >
               {content}
            </Typography>
            <Button variant='primaryBtn' onClick={onClose}>
               <Typography>{t('model.ButtonCTA')}</Typography>
            </Button>
         </Box>
      </Modal>
   );
};

export default ModalComponent;
