import React from 'react';
import { Button, Typography } from '@mui/material';
import { useGoogleLogin } from '@react-oauth/google';
import { useNavigate } from 'react-router-dom';
import { routes } from 'routes/routes';
import { loginUserWithGoogleForAnonymous } from 'store/auth/auth.slice';
import { LoggedUserTypes } from 'store/auth/types';
import { useAppDispatch } from 'store/hooks';
import GoogleIcon from '../loginIcons/GoogleIcon';

const LoginWithGoogle: React.FC = () => {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();

   const googleLogin = useGoogleLogin({
      onSuccess: async (codeResponse) => {
         try {
            const response = await dispatch(loginUserWithGoogleForAnonymous(codeResponse.access_token));
            if (response.payload?.user) {
               const { user } = response.payload as LoggedUserTypes;

               if (user.subscription) {
                  navigate(routes().myProfile);
               } else if (user.details?.creatorCoupon) {
                  navigate(`${routes().creatorFlow.base}/${routes().creatorFlow.creatorImpact}`);
               } else {
                  navigate(routes().causes);
               }
            }
         } catch (errorA) {
            console.log('Google error', errorA);
         }
      },
      onError: (errorA) => console.log('Login Failed:', errorA),
   });

   const loginWithGoogle = () => {
      sessionStorage.clear();
      googleLogin();
   };
   return (
      <Button onClick={loginWithGoogle} variant='loginBtn'>
         <GoogleIcon />
         <Typography sx={{ ml: '5px' }}>Continue with Google</Typography>
      </Button>
   );
};

export default LoginWithGoogle;
