import styled from '@emotion/styled';

export const StyledInput = styled.input`
   width: 100%;
   border: 2px solid #ccc;
   padding: 16px;
   background: #fff;
   height: 54px;
   border-radius: 8px;
   outline: none;
   font-size: 18px;

   &:focus,
   &:focus-visible {
      border-color: #007bff !important;
   }

   &::placeholder {
      font-size: 18px;
      font-weight: 500;
      color: #999;
      line-height: 21.78px;
   }
`;
