import React from 'react';
import { styled, Typography, TypographyProps, Box, BoxProps, Button, ButtonProps } from '@mui/material';

export const CopyBox = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' pl={1} pr={1} pt={1.25} pb={1.25} {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   display: 'flex',
   justifyContent: 'space-between',
   alignItems: 'center',
   width: '100%',
   minHeight: '48px',
   position: 'relative',
   textAlign: 'center',
   borderRadius: theme.typography.pxToRem(theme.size.font_36),
   border: `2px solid ${theme.palette.black.main}`,
}));

export const CopyLinkText = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='p' fontSize={14} {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   lineHeight: '20px',
   fontWeight: theme.typography.fontWeightRegular,
}));

export const LinkLabel = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='p' fontSize={16} {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   fontWeight: theme.typography.fontWeightMedium,
}));

export const SkipButton = styled(({ children, ...props }: ButtonProps) => (
   <Button variant='text' {...props}>
      {children}
   </Button>
))(({ theme }) => ({
   fontWeight: theme.typography.fontWeightMedium,
   textTransform: 'none',
   fontSize: theme.typography.pxToRem(theme.size.font_18),
   color: theme.palette.black.main,
   textDecoration: 'underline',
   lineHeight: '24px',
   boxShadow: 'none',
   '&:hover': {
      background: 'none',
      boxShadow: 'none',
   },
}));

export const SupportersBox = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   justifyContent: 'space-between',
   background: theme.palette.white.main,
   height: '457px',
   width: '322px',
   padding: '14px 20px',
   borderRadius: theme.typography.pxToRem(theme.size.font_36),
   boxShadow: '0 4px 24px rgba(0, 0, 0, 0.25)',
}));

export const FinalSupporterText = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='p' {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   fontWeight: theme.typography.fontWeightBold,
   fontSize: theme.typography.pxToRem(theme.size.font_28),
   textTransform: 'uppercase',
   padding: '0 12px',
}));

export const FinalCauseItem = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' gap={1} borderRadius={4.5} p={0.5} {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   textAlign: 'center',
   width: '76px',
   height: '131px',
   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.15)',
   background: theme.palette.white.main,
   borderRadius: theme.typography.pxToRem(theme.size.font_36),
   wordBreak: 'break-word',
}));

export const FinalCauseTitle = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='p' {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   fontWeight: theme.typography.fontWeightBold,
   fontSize: theme.typography.pxToRem(theme.size.font_12),
   textTransform: 'uppercase',
}));

export const FinalMemberText = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='p' {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   fontWeight: theme.typography.fontWeightLight,
   fontSize: theme.typography.pxToRem(theme.size.font_12),
   textTransform: 'uppercase',
}));

export const FinalDate = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='p' {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   fontWeight: theme.typography.fontWeightBold,
   fontSize: theme.typography.pxToRem(theme.size.font_26),
   textTransform: 'uppercase',
}));
