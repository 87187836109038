import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { IMAGE } from 'enums/images';
import { routes } from 'routes/routes';
import { PageWrapper } from 'wrappers';
import { ENUM_ICON } from 'enums/icons';
import { Icon } from 'components/icon';
import { useAppDispatch } from 'store/hooks';
import { addPassword, createAnonymousSignup, fetchLoggedUser, addEmail } from 'store/auth/auth.slice';
import { PageMainLabel, ButtonFurther, ContentWrapper, FixedBottomNav, IconCaptionNumber } from 'components/common';
import { LoggedUserTypes } from 'store/auth/types';
import { CenteredWrapper } from './styles';

export function IntroPage() {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const [isButtonDisabled, setIsButtonDisabled] = useState(false);

   const handleNext = () => {
      setIsButtonDisabled(true);
      dispatch(createAnonymousSignup()).then(({ payload }) => {
         if (payload.response?.status === 409) {
            // Error Handle
            return;
         }
         // Save the data in the redux
         dispatch(addPassword(payload.password));
         dispatch(addEmail(payload.user.email));
         dispatch(fetchLoggedUser()).then((res) => {
            setIsButtonDisabled(false);
            navigate(routes().causes);
         });
      });
   };

   return (
      <PageWrapper logo>
         <ContentWrapper pb={11.5}>
            <Box component='div' width='100%' display='flex' flexDirection='column' gap={2.5}>
               <PageMainLabel>How does Bono work?</PageMainLabel>
               <CenteredWrapper>
                  <IconCaptionNumber iconText='1'>Choose causes to fix</IconCaptionNumber>
                  <IconCaptionNumber iconText='2'>Get a nonprofit portfolio</IconCaptionNumber>
                  <IconCaptionNumber iconText='3'>Set a monthly giving plan</IconCaptionNumber>
                  <IconCaptionNumber iconText='4'>Receive weekly impact updates</IconCaptionNumber>
                  <div style={{ textAlign: 'center' }}>
                     <Box
                        component='img'
                        alt='Company logo'
                        width='150px'
                        height='150px'
                        sx={{ aspectRatio: '1/1', marginTop: '50px', marginBottom: '50px', justifyContent: 'center' }}
                        src={IMAGE.BONO_LOGO_walk}
                     />
                  </div>
               </CenteredWrapper>
            </Box>
            <FixedBottomNav>
               <ButtonFurther
                  fullWidth
                  onClick={handleNext}
                  disabled={isButtonDisabled}
                  endIcon={isButtonDisabled ? '' : <Icon name={ENUM_ICON.ARROW_RIGHT} />}
               >
                  {isButtonDisabled ? 'Loading...' : 'Choose your causes'}
               </ButtonFurther>
            </FixedBottomNav>
         </ContentWrapper>
      </PageWrapper>
   );
}
