/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';

import { routes } from 'routes/routes';
import { PageWrapper } from 'wrappers';
import { IMAGE } from 'enums/images';
import { PageMainLabel, PageSubLabel } from 'components/common';
import Logo2 from 'components/Logo2';

const useGlobalStyle = process.env.REACT_APP_BONO_GLOBAL === 'true';

export const GeneratingProfilePage = () => {
   const navigate = useNavigate();
   const { t } = useTranslation();
   const textList = [
      t('portfolioGeneration.subHeading1'),
      t('portfolioGeneration.subHeading2'),
      t('portfolioGeneration.subHeading3'),
   ];
   const generatingProfileTime = Number(process.env.REACT_APP_TEXT_GENERATING_INTERVAL) || 2000;
   const timeBeforeNextPage = generatingProfileTime * textList.length;
   const screenSE = useMediaQuery('(max-height:700px)');

   const [currentText, setCurrentText] = useState(0);

   useEffect(() => {
      const timeoutId = setTimeout(() => {
         navigate(routes().portfolio);
      }, timeBeforeNextPage);

      return () => clearTimeout(timeoutId);
   }, []);

   useEffect(() => {
      const intervalId = setInterval(() => {
         setCurrentText((prevIndex) => (prevIndex + 1) % textList.length);
      }, generatingProfileTime);

      document.title = `Generating Profile | ${t('siteTitleName')}`;

      return () => clearInterval(intervalId);
   }, []);

   return (
      <PageWrapper
         logo={false}
         sx={{
            width: '100%',
            maxWidth: '100%',
            height: '100dvh',
            overflow: 'hidden',
            pt: { xs: 0, sm: 6 },
         }}
         logoSpace={!screenSE}
      >
         <Box
            component='section'
            display='flex'
            flexDirection='column'
            width='100%'
            paddingBottom='50%'
            justifyContent='center'
         >
            <Box component='div' width='100%' display='flex' flexDirection='column' textAlign='center'>
               <Box component='div' mb={3.75}>
                  <Logo2 />
               </Box>
               <Box component='div' width='100%' display='flex' flexDirection='column' gap={2.25}>
                  <PageMainLabel sx={{ textTransform: 'none', textAlign: { xs: 'center' } }}>
                     <Trans i18nKey='portfolioGeneration.heading' components={{ 1: <br /> }} />
                  </PageMainLabel>
                  {textList.map((text, index) =>
                     currentText === index ? (
                        <PageSubLabel
                           key={text}
                           sx={{
                              textAlign: { xs: 'center' },
                              opacity: index === currentText ? 1 : 0,
                              transform: 'scale(1)',
                              transition: 'opacity 1s, transform 0.1s',
                              animation: 'pulse 3s infinite',
                              '@keyframes pulse': {
                                 '0%': {
                                    transform: 'scale(1)',
                                 },
                                 '50%': {
                                    transform: 'scale(1.05)',
                                 },
                                 '100%': {
                                    transform: 'scale(1)',
                                 },
                              },
                           }}
                        >
                           {text}
                        </PageSubLabel>
                     ) : null
                  )}
               </Box>
            </Box>
         </Box>
      </PageWrapper>
   );
};
