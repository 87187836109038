import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { routes } from 'routes/routes';
import { PageWrapper } from 'wrappers';
import { useAppDispatch } from 'store/hooks';
import PasswordTextField from 'components/password-textfield';
import { Icon } from 'components/icon';
import { ENUM_ICON } from 'enums/icons';
import { addCodeFromEmail } from 'store/auth/auth.slice';
import { BottomContentBox, ButtonFurther, ContentWrapper, PageMainLabel, PageSubLabel } from 'components/common';

export function ResetPasswordPage() {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const inputRef = useRef<HTMLDivElement | null>(null);
   const sessionProfileActivated = sessionStorage.getItem('profileWasActivated');
   const { t } = useTranslation();
   const [isFocused, setIsFocused] = useState(false);
   const [code, setCode] = useState('');
   const [error, setError] = useState(false);

   const handleCodeChange = (newCode: string) => {
      if (newCode.length === 0) {
         setError(false);
      }
      setCode(newCode);
      dispatch(addCodeFromEmail(newCode));
   };

   const handleNext = () => {
      if (code && code.length > 0) {
         setError(false);
         navigate(routes().newPassword);
      }
   };

   useEffect(() => {
      const handleKeyPress = (event: KeyboardEvent) => {
         if (event.key === 'Enter') {
            event.preventDefault();
            handleNext();
         }
      };
      if (inputRef && inputRef.current) {
         inputRef.current.addEventListener('keypress', handleKeyPress);
      }

      return () => {
         if (inputRef && inputRef.current) {
            inputRef.current.removeEventListener('keypress', handleKeyPress);
         }
      };
   }, [code]);

   const backButtonAction = () => {
      if (sessionProfileActivated && JSON.parse(sessionProfileActivated)) {
         navigate(routes().myProfile);
      } else {
         navigate(routes().loginPassword);
      }
   };

   return (
      <PageWrapper leftButton={{ onClick: () => backButtonAction() }}>
         <ContentWrapper>
            <Box component='div' width='100%' display='flex' flexDirection='column' gap={2.5} mb={3.75}>
               <PageMainLabel>{t('insertYourCode.heading')}</PageMainLabel>
               <PageSubLabel>{t('insertYourCode.subheading')}</PageSubLabel>
               <PasswordTextField
                  autoFocus
                  fullWidth
                  value={code}
                  ref={inputRef}
                  placeholder='Code'
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  error={error}
                  onChange={(e) => handleCodeChange(e.target.value)}
                  helperText={t('chooseYourPassword.codeFieldErrorMessage1EmptyPassword')}
               />
            </Box>
            <BottomContentBox isFocused={isFocused}>
               <ButtonFurther endIcon={<Icon name={ENUM_ICON.ARROW_RIGHT} />} onClick={handleNext}>
                  {t('insertYourCode.buttonCTA')}
               </ButtonFurther>
            </BottomContentBox>
         </ContentWrapper>
      </PageWrapper>
   );
}
