import React from 'react';
import { styled, Box, BoxProps, Typography, TypographyProps, Container, ContainerProps } from '@mui/material';

export const WelcomePageWrapper = styled(({ children, ...props }: ContainerProps) => (
   <Container component='section' maxWidth='xs' {...props}>
      {children}
   </Container>
))(({ theme }) => ({
   width: '100vw',
   height: '100svh',
   display: 'flex',
   flexDirection: 'column',
   justifyContent: 'space-between',
   paddingLeft: '0 !important',
   paddingRight: '0 !important',
   paddingBottom: '30px',
   position: 'relative',
}));

export const AbsoluteLogoBox = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' pl={3.75} pr={3.75} zIndex={1} {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   position: 'absolute',
   top: 0,
   width: '100%',
   display: 'flex',
   justifyContent: 'center',
   marginBottom: '88px',
   paddingTop: theme.typography.pxToRem(theme.size.font_20),
   '@media(max-height: 700px)': {
      marginBottom: '20px',
   },
}));

export const ButtonsBox = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   display: 'flex',
   flexDirection: 'column',
   width: '100%',
   textAlign: 'center',
}));

export const CarouselContentBox = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' gap={3.75} pl={3.75} pr={3.75} {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   width: '100%',
   height: '100%',
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   m: 'auto',
   '@media(max-height: 700px)': {
      gap: '20px',
   },
}));

export const CenteredWrapper = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' gap={1.2} {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   width: '100%',
   textAlign: 'left',
   display: 'flex',
   flexDirection: 'column',
}));

export const ImageLabel = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='h3' fontSize={50} {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   fontWeight: theme.typography.fontWeightBold,
   lineHeight: '50px',
}));

export const ImageDescription = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='h3' fontSize={18} {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   fontWeight: 600,
   lineHeight: '22px',
   fontFamily: "'Inter', 'Arial', sans-serif",
}));

export const PageMainLabel = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='h1' fontSize={34} {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   fontWeight: 700,
   textTransform: 'none',
   lineHeight: '38px',
   fontFamily: 'Figtree',
   wordWrap: 'break-word',
}));

export const PageSubLabel = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='h2' fontSize={18} {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   fontWeight: 500,
   textTransform: 'none',
   lineHeight: '22px',
   fontFamily: 'Figtree',
   wordWrap: 'break-word',
   '& > span': {
      fontWeight: theme.typography.fontWeightBold,
   },
}));
