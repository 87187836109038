import React, { useState, useEffect, useRef } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { routes } from 'routes/routes';
import { PageWrapper } from 'wrappers';
import { useAppDispatch } from 'store/hooks';
import { Icon } from 'components/icon';
import { ENUM_ICON } from 'enums/icons';
import { BasicModal } from 'modals/basic.modal';
import { sendNewUserWithCode, addPassword, fetchLoggedUser } from 'store/auth/auth.slice';
import PasswordTextField from 'components/password-textfield';
import { BottomContentBox, ButtonFurther, ContentWrapper, PageMainLabel, PageSubLabel } from 'components/common';

export function NewPasswordPage() {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const inputRef = useRef<HTMLDivElement | null>(null);
   const { t } = useTranslation();
   const [isFocused, setIsFocused] = useState(false);
   const [password, setPassword] = useState('');
   const [error, setError] = useState(false);
   const [modal, setModal] = useState(false);
   const [submitting, setSubmitting] = useState(false);

   const handlePasswordChange = (newPassword: string) => {
      if (newPassword.length === 0) {
         setError(false);
      }
      setPassword(newPassword);
      dispatch(addPassword(newPassword));
   };

   const handleNext = () => {
      if (password.length >= 8) {
         setError(false);
         setSubmitting(true);
         dispatch(sendNewUserWithCode()).then(() => {
            dispatch(fetchLoggedUser()).then(() => {
               analytics?.track('password_reset');
               setModal(true);
               setSubmitting(false);
            });
         });
      } else {
         setError(true);
      }
   };

   useEffect(() => {
      const handleKeyPress = (event: KeyboardEvent) => {
         if (event.key === 'Enter') {
            event.preventDefault();
            handleNext();
         }
      };
      if (inputRef && inputRef.current) {
         inputRef.current.addEventListener('keypress', handleKeyPress);
      }

      return () => {
         if (inputRef && inputRef.current) {
            inputRef.current.removeEventListener('keypress', handleKeyPress);
         }
      };
   }, [password]);

   const handleCloseModal = () => {
      setModal(false);
      navigate(routes().myProfile);
   };

   return (
      <PageWrapper leftButton={{ onClick: () => navigate(routes().resetPassword) }}>
         <ContentWrapper>
            <Box component='div' width='100%' display='flex' flexDirection='column' gap={2.5} mb={3.75}>
               <PageMainLabel>{t('chooseYourPassword.heading')}</PageMainLabel>
               <PageSubLabel>{t('chooseYourPassword.subheading')}</PageSubLabel>
               <PasswordTextField
                  autoFocus
                  fullWidth
                  ref={inputRef}
                  value={password}
                  placeholder='Password'
                  onFocus={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  error={error}
                  onChange={(e) => handlePasswordChange(e.target.value)}
                  helperText={t('chooseYourPassword.codeFieldErrorMessage1EmptyPassword')}
               />
            </Box>
            <BottomContentBox isFocused={isFocused}>
               <ButtonFurther
                  endIcon={submitting ? null : <Icon name={ENUM_ICON.ARROW_RIGHT} />}
                  disabled={submitting}
                  onClick={handleNext}
               >
                  {submitting ? <CircularProgress size={24} color='white' /> : `${t('insertYourCode.buttonCTA')}`}
               </ButtonFurther>
            </BottomContentBox>
         </ContentWrapper>
         <BasicModal
            open={modal}
            onClose={handleCloseModal}
            onNext={handleCloseModal}
            title={t('chooseYourPassword.successPopupHeading')}
            description='You successfully changed your password'
            buttonLabel={t('chooseYourPassword.successPopupCTA')}
         />
      </PageWrapper>
   );
}
