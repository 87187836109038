import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Import useParams
import { useTranslation } from 'react-i18next';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import { createPortal } from 'react-dom';
import ProfileLayout from 'components/profileLayout/profileLayout';
import {
   Slide,
   ImpactUpdateLongDescription,
   ImpactUpdateShortDescription,
} from 'components/my-profile/impact-updates/impactUpdates';
import { fetchImpactReportBySlug, getWidgetFromApiURL } from 'store/widget/widget.services';
import { LoadingIcon } from 'components/common';
import { fetchUserBySlug } from 'store/auth/auth.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { routes } from 'routes/routes';
import { causesState } from 'store/causes/causes.slice';
import DynamicGoogleIcon from 'components/dynamic-icon/dynamicGoogleIcon';
import { AboutBono } from '../../components/my-profile/widgets/common';
import BonoLogo from '../../components/bono-logo/bonologo';

// Define types for display views data
interface WidgetFormat {
   text?: string;
   title?: string;
   showLogo?: boolean;
   buttonText?: string;
   CTA?: string;
   ctaUrl?: string;
   ctaTitle?: string;
}

export function ImpactDetailPage(): React.ReactElement {
   const [userData, setUserData] = useState<any>(null);
   const dispatch = useAppDispatch();
   const { slug, impcatId } = useParams();
   const [images, setImages] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const { t } = useTranslation();
   const navigate = useNavigate();
   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
   const sharelink = `${process.env.REACT_APP_BASE_URL}/p/${userData?.publicName}?ref_id=${userData?.settings?.referralCode}`;
   const backBtnUrl = `/p/${userData?.publicName}`;

   const fetchImpacts = async (validatedSlug: string) => {
      try {
         const response = await fetchImpactReportBySlug(validatedSlug);
         setImages(
            response.data.posts.map((item: any) => {
               const cause = response.data.causes.find((c: any) => c.id === item.causeId);
               return {
                  ...item,
                  cause: cause?.title,
                  causeBackground: cause?.impactBackground,
               };
            })
         );
         dispatch(fetchUserBySlug(validatedSlug))
            .then((responseUser) => {
               setUserData({ public: true, ...responseUser.payload });
            })
            .catch((error) => {
               console.log(error);
               navigate(routes().welcome);
            });
      } catch (error) {
         console.error('Error fetching data:', error);
      } finally {
         setIsLoading(false);
      }
   };

   async function fetchData() {
      try {
         if (slug) {
            fetchImpacts(slug);
         } else {
            navigate(routes().welcome);
         }
      } catch (error) {
         console.error('Error fetching data:', error);
      }
   }

   useEffect(() => {
      fetchData();
   }, []);

   return (
      <ProfileLayout>
         {isLoading ? (
            <Box
               sx={{
                  width: '100%',
                  height: '100%',
                  marginTop: '20px',
                  minHeight: 'calc(100dvh - 300px)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  background: '#E8E8E8',
                  borderRadius: '4px',
               }}
            >
               <LoadingIcon />
            </Box>
         ) : (
            <Box sx={{ display: 'flex' }} pt={{ md: 3 }} pb={{ md: 8 }}>
               <Box sx={{ flex: 1 }} pr={{ xs: 0, md: '29px' }}>
                  {isMobile ? (
                     createPortal(
                        <Box
                           sx={{
                              position: 'absolute',
                              zIndex: '9999999999',
                              top: '23px',
                              right: '20px',
                           }}
                        >
                           <Box
                              onClick={() => {
                                 navigate(backBtnUrl);
                              }}
                              sx={{
                                 display: 'flex',
                                 cursor: 'pointer',
                                 width: '30px',
                              }}
                           >
                              <CloseIcon
                                 sx={{
                                    fill: '#fff',
                                 }}
                              />
                           </Box>
                        </Box>,
                        document.getElementById('mobileHeader') || document.body
                     )
                  ) : (
                     <Box
                        sx={{
                           padding: '16px 0px',
                        }}
                     >
                        <Box
                           onClick={() => {
                              navigate(backBtnUrl);
                           }}
                           sx={{
                              display: 'flex',
                              cursor: 'pointer',
                              width: '100px',
                           }}
                        >
                           <ArrowBackIcon
                              sx={{
                                 fill: '#333',
                              }}
                           />
                           <Typography
                              sx={{
                                 marginLeft: '4px',
                                 color: '#333',
                                 fontWeight: 700,
                                 fontSize: '14px',
                              }}
                           >
                              {t('impactDetails.backBtn')}
                           </Typography>
                        </Box>
                     </Box>
                  )}
                  <ImpactDetail impact={images.find((impact: any) => impact.id == impcatId)} />
               </Box>
               <Box
                  sx={{
                     borderLeft: { xs: 'none', md: '1px solid rgba(51, 51, 51, .3)' },
                     width: { xs: '0px', md: '330px', lg: '370px' },
                     overflow: 'hidden',
                  }}
                  pl={{ xs: 0, md: '29px' }}
                  pt={{ xs: 0, md: '6px' }}
               >
                  <Stack direction='column' spacing={0}>
                     <ImpactList images={images.filter((item: any) => item.id != impcatId).slice(0, 3)} slug={slug} />
                     <Box
                        sx={{
                           marginTop: '36.5px',
                           display: { xs: 'none', md: 'block' },
                           order: 10,
                        }}
                     >
                        <AboutBono shareLink={sharelink} isPublic={false} />
                     </Box>
                  </Stack>
               </Box>
            </Box>
         )}
      </ProfileLayout>
   );
}

function ImpactDetail({ impact }: { impact: any }) {
   const { t } = useTranslation();
   return (
      <Box
         sx={{
            order: 1,
         }}
      >
         {impact ? (
            <>
               <Box
                  sx={{
                     marginTop: { xs: '30px', sm: '0px' },
                     width: '100%',
                     flexShrink: 0,
                     aspectRatio: { xs: '360/190', md: '560/290' },
                     borderRadius: { xs: '0px', md: '10px' },
                     overflow: 'hidden',
                     position: 'relative',
                     cursor: 'pointer',
                  }}
               >
                  <Box
                     component='img'
                     sx={{
                        height: '100%',
                        width: '100%',
                        position: 'absolute',
                        objectFit: 'cover',
                        top: 0,
                        left: 0,
                     }}
                     alt={impact.title}
                     src={impact.displayImage}
                  />
                  <Box
                     sx={{
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        position: 'relative',
                        padding: '20px',
                        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0) 50%);',
                        alignItems: 'flex-end',
                     }}
                  >
                     <Box sx={{ display: 'grid', gap: '6px' }}>
                        <Box
                           sx={{
                              backgroundColor: impact.causeBackground,
                              padding: '2px 6px',
                              borderRadius: '22px',
                              maxWidth: 'fit-content',
                           }}
                        >
                           <Typography
                              sx={{ fontFamily: 'Inter', fontWeight: '700', fontSize: '10px', lineHeight: '18px' }}
                           >
                              {impact.cause}
                           </Typography>
                        </Box>
                        <Typography
                           sx={{
                              color: '#fff',
                              fontFamily: 'Inter',
                              fontWeight: '700',
                              fontSize: '24px',
                              lineHeight: '29.05px',
                           }}
                        >
                           {impact.title}
                        </Typography>
                     </Box>
                  </Box>
               </Box>
               <Box sx={{ px: '15px', py: '30px', display: 'flex', flexDirection: 'column', gap: '30px' }}>
                  <ImpactUpdateShortDescription text={impact.tldr} />
                  <ImpactUpdateLongDescription text={impact.body} />
                  {/*
                  <Box sx={{ pt: { xs: '0px', sm: '15px' } }}>
                     <BlueButton networkData={impact.source} buttonText='Share this update' width='100%' />
                  </Box>
                  */}
               </Box>
            </>
         ) : (
            <>Loading</>
         )}
      </Box>
   );
}

function ImpactList({ images, slug }: { images: any; slug: string | undefined }) {
   const { t } = useTranslation();

   return (
      <Box
         sx={{
            display: 'flex',
            gap: '10px',
            flexDirection: 'column',
         }}
      >
         <Typography
            sx={{
               fontFamily: 'Inter',
               fontWeight: '700',
               fontSize: '20px',
               lineHeight: '40px',
               color: '#333',
               width: '100%',
            }}
         >
            More impact Updates
         </Typography>

         {images.length === 0 && (
            <Box
               sx={{
                  width: '100%',
                  height: '100%',
                  minHeight: '100px',
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
               }}
            >
               {t('noDataFoundError')}
            </Box>
         )}
         {images.map((post: any) => {
            return <Slide key={`post-${post.id.toString()}`} post={post} slug={slug} />;
         })}
      </Box>
   );
}

function MediumBox(props: { icon: string; number: React.ReactNode; text: React.ReactNode }) {
   const { icon, number, text } = props;
   return (
      <Box sx={{ backgroundColor: '#fff', padding: '20px', borderRadius: '4px', height: '100%' }}>
         <DynamicGoogleIcon iconName={icon} />
         <Typography
            sx={{ fontFamily: 'Inter', color: '#333', fontWeight: '700', fontSize: '26px', lineHeight: '31.47px' }}
         >
            {number}
         </Typography>
         <Typography
            sx={{ fontFamily: 'Inter', color: '#999', fontSize: '12px', fontWeight: '600px', lineHeight: '14.52px' }}
         >
            {text}
         </Typography>
      </Box>
   );
}

function DisplayDataWidget(props: any) {
   const [data, setData] = useState<any | null>(null);
   const [isLoading, setIsLoading] = useState(true);

   const {
      data: { widgetFormat, dataApiCall } = {},
   }: {
      data?: {
         widgetFormat?: WidgetFormat;
         dataApiCall?: string;
      };
   } = props || {};

   const { title, buttonText, CTA, showLogo } = widgetFormat || {};

   const fetchData = async (url: string) => {
      try {
         const response = await getWidgetFromApiURL(url);
         setData(Array.isArray(response.data) ? response.data : props?.data?.data);
      } catch (error) {
         setData(props?.data?.data);
         console.error('Error fetching data:', error);
      } finally {
         setIsLoading(false);
      }
   };

   useEffect(() => {
      if (dataApiCall) {
         fetchData(dataApiCall);
      }
   }, [dataApiCall]);

   return (
      <Box sx={{ display: 'flex', gap: '10px', flexDirection: 'column', mb: '30px' }}>
         {(title || buttonText) && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: '30px' }}>
               <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', gap: '30px' }}>
                  <Typography
                     sx={{
                        fontFamily: 'Inter',
                        color: '#333',
                        fontWeight: '700',
                        fontSize: '16px',
                        lineHeight: '19.36px',
                     }}
                  >
                     {title}
                  </Typography>

                  {buttonText && (
                     <a href={CTA}>
                        <Typography
                           sx={{
                              color: '#4D7BF1',
                              fontFamily: 'Inter',
                              fontWeight: '600',
                              fontSize: '14px',
                              lineHeight: '16.94px',
                              cursor: 'pointer',
                           }}
                        >
                           {buttonText}
                        </Typography>
                     </a>
                  )}
               </Box>
               {showLogo && <BonoLogo width='53px' />}
            </Box>
         )}
         {isLoading ? (
            <Box
               sx={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  minHeight: '120px',
                  alignItems: 'center',
                  background: '#E8E8E8',
                  borderRadius: '4px',
               }}
            >
               <LoadingIcon />
            </Box>
         ) : (
            <Box
               sx={{ display: 'grid', gridTemplateColumns: `repeat(${Math.min(data?.length, 3)}, 1fr)`, gap: '10px' }}
            >
               {Array.isArray(data) &&
                  data?.map((singleData: any) => {
                     const { icon, txtData, subHeader, order } = singleData;
                     return (
                        <div key={subHeader} style={{ order }}>
                           {/* TODO give new type and divide value by 100 */}
                           <MediumBox icon={icon} number={txtData} text={subHeader} />
                        </div>
                     );
                  })}
            </Box>
         )}
      </Box>
   );
}
