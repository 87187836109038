import api from 'api';
import { CauseTypes } from 'store/causes/types';

// http://localhost:3001/v1/impactIndicator/me

export const fetchWidgets = () => {
   return api.get('/v1/widget/personal');
};

export const fetchWidgetsPublic = (slug: string) => {
   return api.get(`/v1/widget/public/${slug}`);
};

export const getUsersMe = () => {
   return api.get('/v1/users/me');
};

export const getWidgetFromApiURL = (url: string) => {
   return api.get(url);
};

export const getUsersMeCauses = () => {
   return api.get('/v1/users/me/causes');
};

export const getImpactMe = () => {
   return api.get('/v1/impactIndicator/me');
};

export const fetchImpactReportBySlug = (slug: string) => {
   return api.get(`/v1/impactReport/slug/${slug}`);
};

export const sendSelectedCauses = (causes: CauseTypes[]) => {
   const arrayOfIds = causes.map((cause) => cause.id).join(',');
   return api.post(`/v1/users/me/causes/ids=${arrayOfIds}`);
};

export const sendSingleCause = (id: number) => {
   return api.post(`/v1/users/me/causes/${id}`);
};

export const getSelectedCauses = () => {
   return api.get('/v1/users/me/causes');
};

export const getCharities = () => {
   return api.get('/v1/users/me/charities');
};

export const getCharitiesBySelectedCauses = () => {
   return api.get('/v1/users/me/charities/suggest');
};
