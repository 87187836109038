import React, { useState, useEffect } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from 'wrappers';
import { routes } from 'routes/routes';
import { authState, fetchUserByAccessToken } from 'store/auth/auth.slice';
import { useAppSelector, useAppDispatch } from 'store/hooks';
import { PageMainLabel, PageSubLabel, ContentWrapper } from 'components/common';
import { AnalyticsTrackPurchase } from 'utils/utils';

export const GratitudePage = () => {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const { loggedUserData } = useAppSelector(authState);
   const { t } = useTranslation();

   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const sessionId = searchParams.get('session_id') || '';

   const selectedCausesData = localStorage.getItem('selectedCauses') || '';
   const convertedCausesList = JSON.parse(selectedCausesData).map((cause: any) => cause.id);
   const items = JSON.parse(sessionStorage.getItem(sessionId) || '[]');

   useEffect(() => {
      if (loggedUserData) {
         const profilePage = `/p/${loggedUserData?.publicName}?welcome=true`;
         AnalyticsTrackPurchase(sessionId, items, convertedCausesList);
         navigate(profilePage);
      }
   }, [loggedUserData]);

   useEffect(() => {
      dispatch(fetchUserByAccessToken());
   }, [dispatch]);

   useEffect(() => {
      document.title = `Thank you! | ${t('siteTitleName')}`;
   }, []);

   return (
      <PageWrapper
         logoSpace={false}
         topPadding={false}
         leftButton={{ onClick: () => navigate(routes().portfolio) }}
         logo={false}
      />
   );
};
