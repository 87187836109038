import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CharityTypes } from 'store/causes/types';
import type { RootState } from 'store';
import { SelectedCharitiesType } from './types';

const initialState: SelectedCharitiesType = {
   selectedCharities: [],
};

export const selectedCharitiesSlice = createSlice({
   name: 'selectedCharitiesSlice',
   initialState,
   reducers: {
      storeSelectedCharities(state, action: PayloadAction<CharityTypes[]>) {
         const selectedCharities = action.payload;
         return {
            ...state,
            selectedCharities,
         };
      },

      handleOnClickCharityCheckbox(state, action: PayloadAction<CharityTypes>) {
         const { id } = action.payload;
         const isExist = state.selectedCharities.find((charity) => charity.id === id);

         if (isExist) {
            return {
               ...state,
               selectedCharities: state.selectedCharities.filter((charity) => charity.id !== id),
            };
         }

         return {
            ...state,
            selectedCharities: [...state.selectedCharities, action.payload],
         };
      },
      clearSelectedCharities(state) {
         state.selectedCharities = [];
         return state;
      },
   },
});

export const { storeSelectedCharities, handleOnClickCharityCheckbox, clearSelectedCharities } =
   selectedCharitiesSlice.actions;

export const selectedCharitiesState = (state: RootState) => state.selectedCharities;
