import React, { useMemo } from 'react';
import { Box, Button, Fab, Menu, Typography } from '@mui/material';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import { authState, clearUserData } from 'store/auth/auth.slice';
import { resetStore } from 'store/creators/creators.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { routes } from 'routes/routes';
import { getLogoutUrlLocalStorage } from 'utils/utils';

export function FloatingUserButton() {
   const [open, setOpen] = React.useState(false);
   const { loggedUserData } = useAppSelector(authState);
   const navigate = useNavigate();

   const dispatch = useAppDispatch();
   const anchorRef = React.useRef<HTMLButtonElement>(null);

   const getFirstLetters = useMemo(() => {
      return loggedUserData?.firstName;
   }, [loggedUserData]);

   const handleToggle = () => {
      setOpen((prevOpen) => !prevOpen);
   };

   const handleClose = (event: Event | React.SyntheticEvent) => {
      if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
         return;
      }

      setOpen(false);
   };

   const onLogout = () => {
      const logoutUrl = getLogoutUrlLocalStorage();
      if (logoutUrl === '/creators') {
         dispatch(resetStore());
      }
      localStorage.clear();
      sessionStorage.clear();
      dispatch(clearUserData());
      navigate(logoutUrl || routes().welcome);
   };

   return (
      <Box>
         {loggedUserData?.email ? (
            <>
               <Fab
                  sx={{
                     p: '4.5px 4px 4.5px 10px',
                     border: {
                        sm: '1px solid #f7f7f7',
                     },
                     height: { sm: '24px' },
                     fontSize: { sm: '12px' },
                  }}
                  variant='extended'
                  size='small'
                  color='black'
                  ref={anchorRef}
                  id='composition-button'
                  aria-controls={open ? 'composition-menu' : undefined}
                  aria-expanded={open ? 'true' : undefined}
                  aria-haspopup='true'
                  onClick={handleToggle}
               >
                  {getFirstLetters}
                  {!open ? (
                     <KeyboardArrowDown sx={{ ml: { xs: '5px', sm: '1px' } }} fontSize='small' />
                  ) : (
                     <KeyboardArrowUp sx={{ ml: { xs: '5px', sm: '1px' } }} fontSize='small' />
                  )}
               </Fab>
               <Menu
                  anchorEl={anchorRef.current}
                  id='account-menu'
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                     elevation: 0,
                     sx: {
                        overflow: 'hidden',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        p: 1.25,
                        mt: { xs: 1.5, sm: 1 },
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        '& .MuiAvatar-root': {
                           width: 32,
                           height: 32,
                           ml: -0.5,
                           mr: 1,
                        },
                     },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
               >
                  <Typography fontSize={12} sx={{ color: '#222', fontWeight: 700 }}>
                     {loggedUserData.firstName}
                  </Typography>
                  <Typography fontSize={12} sx={{ color: '#222', fontWeight: 400, mb: 2 }}>
                     {loggedUserData.email}
                  </Typography>
                  <Button
                     onClick={onLogout}
                     sx={{ minWidth: '150px', width: '100%' }}
                     color='black'
                     variant='contained'
                  >
                     Logout
                  </Button>
               </Menu>
            </>
         ) : (
            <Button
               sx={{
                  border: { sm: '#f7f7f7 1px solid' },
                  boxShadow: { xs: 'none' },
                  borderRadius: '100px',
                  height: '23px',
                  width: '57px',
                  fontWeight: '500',
                  fontSize: '12px',
               }}
               onClick={() => {
                  navigate(routes().signIn);
               }}
               color='black'
               variant='contained'
            >
               Login
            </Button>
         )}
      </Box>
   );
}
