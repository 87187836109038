import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import type { CommonSliceTypes } from './types';

const initialState: CommonSliceTypes = {
   profileWasActivated: false,
   portfolioDoneBtn: false,
   project: '',
};

export const commonSlice = createSlice({
   name: 'commonSlice',
   initialState,
   reducers: {
      addProject(state, action: PayloadAction<string>) {
         return {
            ...state,
            project: action.payload,
         };
      },
   },
});

export const { addProject } = commonSlice.actions;

export const commonState = (state: RootState) => state.common;
