/* eslint-disable jsx-a11y/anchor-is-valid */
import { Box, Button, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IMAGE } from 'enums/images';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { addCreatorCoupon, authState } from 'store/auth/auth.slice';
import { setLogoutUrlLocalStorage } from 'utils/utils';
import { clearCalculatedIdicators } from 'store/creators/creators.slice';

export interface StepComponentProps {
   isMobile: boolean;
   onNext: () => void;
   onPrev?: () => void;
}

export const colors = {
   button: '#B415FF',
} as const;

export function WelcomeStep({ isMobile, onNext, onPrev }: StepComponentProps): React.ReactElement {
   const [isClickNext, setIsClickNext] = useState(false);
   const dispatch = useAppDispatch();
   const { pathname } = useLocation();

   const handleClickStart = () => {
      setLogoutUrlLocalStorage(pathname);
      dispatch(clearCalculatedIdicators());
      setIsClickNext(true);
   };

   // Check if user a coupon code is available
   // REACT_APP_CREATOR_COUPON
   const { signupData } = useAppSelector(authState);

   useEffect(() => {
      if (signupData?.details?.creatorCoupon === undefined && process.env.REACT_APP_CREATOR_COUPON) {
         dispatch(addCreatorCoupon(process.env.REACT_APP_CREATOR_COUPON));
      }
   }, [signupData]);

   return (
      <motion.div
         initial={{ opacity: 1 }}
         animate={isClickNext && { opacity: 0 }}
         transition={{ duration: 0.3 }}
         onAnimationComplete={onNext}
         style={{
            height: '100%',
         }}
      >
         <Box
            sx={{
               width: '100%',
               display: 'flex',
               flexDirection: 'column',
               alignItems: 'center',
               justifyContent: 'start',
               gap: 3.75,
               paddingBottom: '160px',
            }}
         >
            {/* <Box
               sx={{
                  position: { xs: 'fixed', sm: 'relative' },
                  bottom: { xs: 0 },
                  height: { xs: '98px', sm: 'unset' },
                  borderTop: { xs: '1px solid #00000016', sm: 'unset' },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: { xs: '100%', sm: '100%' },
               }}
            >
            </Box> */}
            {/* <Typography sx={{ fontWeight: 900 }} variant='h4' component='h1'>
               Welcome to Bono
            </Typography>
            <Box>
               <Typography
                  sx={{ fontSize: '18px', color: '#666', fontWeight: 500, textAlign: 'center' }}
                  variant='subtitle1'
                  component='div'
               >
                  Let&apos;s start by calculating <br /> the impact you can make this year
                  <br />
                  with your community of followers
               </Typography>
            </Box> */}
            <Box
               sx={{
                  width: { xs: '600px', sm: '400px' },
                  padding: { xs: '30px 20px', sm: '40px 20px 0 20px' },
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'start',
                  backgroundColor: { xs: '#EBE9E5', sm: 'transparent' },
               }}
            >
               {isMobile && (
                  <img
                     src={IMAGE.MAIN_LOGO}
                     alt='Bono'
                     style={{
                        margin: '0px auto',
                        width: '59px',
                        paddingBottom: '20px',
                     }}
                  />
               )}
               <Typography
                  sx={{ fontWeight: 900, textAlign: 'center', lineHeight: '38px' }}
                  variant='h4'
                  component='h1'
                  fontSize='34px'
               >
                  A better way <br /> to donate
               </Typography>
            </Box>
            <Typography sx={{ fontWeight: 500 }} variant='h4' component='p' fontSize='18px'>
               Simple. Trusted. Smart. Tell us what you care about, set a monthly budget and let us go to work for you.
            </Typography>
            <div
               style={{
                  position: 'relative',
                  width: '100%',
               }}
            >
               <div
                  style={{
                     position: 'absolute',
                     backgroundImage: `url(${IMAGE.DONATION_AVATAR6})`,
                     borderRadius: '100%',
                     width: '70px',
                     height: '70px',
                     top: '43px',
                     left: '-58px',
                     zIndex: 0,
                     backgroundPosition: 'center',
                     backgroundSize: '70px auto',
                  }}
               />
               <div
                  style={{
                     position: 'absolute',
                     backgroundImage: `url(${IMAGE.DONATION_AVATAR4})`,
                     borderRadius: '100%',
                     width: '70px',
                     height: '70px',
                     top: '12px',
                     left: '120px',
                     backgroundPosition: 'center',
                     backgroundSize: '70px auto',
                  }}
               />
               <div
                  style={{
                     position: 'absolute',
                     backgroundImage: `url(${IMAGE.DONATION_AVATAR2})`,
                     borderRadius: '100%',
                     width: '95px',
                     height: '95px',
                     top: '12px',
                     left: '247px',
                     backgroundPosition: 'center',
                     backgroundSize: '95px auto',
                  }}
               />
               <div
                  style={{
                     position: 'absolute',
                     backgroundImage: `url(${IMAGE.DONATION_AVATAR5})`,
                     borderRadius: '100%',
                     width: '74px',
                     height: '74px',
                     top: '200px',
                     left: '-43px',
                     zIndex: 90,
                     backgroundPosition: 'center',
                     backgroundSize: '74px auto',
                  }}
               />
               <div
                  style={{
                     position: 'absolute',
                     backgroundImage: `url(${IMAGE.DONATION_AVATAR3})`,
                     borderRadius: '100%',
                     width: '73px',
                     height: '73px',
                     top: '203px',
                     left: '184px',
                     backgroundPosition: 'center',
                     backgroundSize: '73px auto',
                  }}
               />
               <div
                  style={{
                     position: 'absolute',
                     backgroundImage: `url(${IMAGE.DONATION_AVATAR1})`,
                     borderRadius: '100%',
                     width: '70px',
                     height: '70px',
                     top: '160px',
                     left: '302px',
                     backgroundPosition: 'center',
                     backgroundSize: '70px auto',
                  }}
               />
               <div style={{ zIndex: 900 }}>
                  <img
                     src={IMAGE.DONATION_BACKGROUND}
                     style={{
                        position: 'absolute',
                        height: '300px',
                        width: 'auto',
                     }}
                     alt=''
                  />
               </div>
               <div style={{ zIndex: 900 }}>
                  <img
                     src={IMAGE.DONATION_ROW1}
                     style={{
                        position: 'absolute',
                        top: '65px',
                        left: '68px',
                        height: '70px',
                        width: 'auto',
                     }}
                     alt=''
                  />
                  <img
                     src={IMAGE.DONATION_ROW2}
                     style={{
                        position: 'absolute',
                        top: '146px',
                        left: '68px',
                        height: '70px',
                        width: 'auto',
                     }}
                     alt=''
                  />
               </div>
            </div>
            <Box
               sx={{
                  position: { xs: 'fixed', sm: 'relative' },
                  bottom: { xs: 0 },
                  width: { xs: '100%', sm: '400px' },
                  height: { xs: '160px', sm: 'unset' },
                  padding: { xs: '0 30px', sm: '0' },
                  paddingTop: { xs: '20px' },
                  paddingBottom: { xs: '30px', sm: 'unset' },
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  top: { sm: '300px' },
                  gap: 3,
                  alignItems: 'center',
                  background: '#F7F7F7CC',
                  zIndex: 100,
               }}
            >
               <Button
                  sx={{
                     mt: { sm: 5.25 },
                     mx: '30px',
                     width: '100%',
                     justifyContent: 'center',
                     textTransform: 'none',
                     maxWidth: '360px',
                  }}
                  className='creatorFlow'
                  variant='contained'
                  size='large'
                  color='black'
                  onClick={handleClickStart}
               >
                  Get Started {'->'}
               </Button>
               <Typography
                  sx={{
                     fontWeight: 500,
                     display: 'flex',
                     flexDirection: 'column',
                     alignItems: 'center',
                     textAlign: 'center',
                     justifyItems: 'center',
                     color: '#666666',
                     '& a': {
                        color: '#666666',
                     },
                     fontSize: { xs: '12px', sm: '16px' },
                  }}
                  variant='h4'
                  component='p'
               >
                  By clicking &quot;Get Started&quot; you agree to our{' '}
                  <span>
                     <a
                        target='_blank'
                        href='https://www.bono.so/tc'
                        style={{ textDecoration: 'underline' }}
                        rel='noreferrer'
                     >
                        Terms & Conditions
                     </a>
                     {', '}
                     <a
                        target='_blank'
                        href='https://docs.google.com/document/d/1Q8iyGz5zXZfCa8fgqsxjGDqw15YaBJaYBkg4_5-mRDk/'
                        style={{ textDecoration: 'underline' }}
                        rel='noreferrer'
                     >
                        Creators Terms
                     </a>
                     {' & '}
                     <a
                        target='_blank'
                        href='https://www.bono.so/privacy-policy'
                        style={{ textDecoration: 'underline' }}
                        rel='noreferrer'
                     >
                        Privacy Policy
                     </a>
                  </span>
               </Typography>
            </Box>
         </Box>
      </motion.div>
   );
}
