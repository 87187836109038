import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
   Box,
   Button,
   Dialog,
   DialogContent,
   DialogContentText,
   DialogTitle,
   DialogActions,
   useMediaQuery,
   useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { WelcomeStep } from 'components/creatorFlow/steps/WelcomeStep';
import { NameStep } from 'components/creatorFlow/steps/NameStep';
import { WelcomeUserAnimation } from 'components/creatorFlow/steps/WelcomeUserAnimation';
import { SocialNetworkStep } from 'components/creatorFlow/steps/SocialNetworksStep';
import { FollowersStep } from 'components/creatorFlow/steps/FollowersStep';
import { ViewsStep } from 'components/creatorFlow/steps/ViewsStep';
import { SocialIssuesStep } from 'components/creatorFlow/steps/SocialIssuesStep';
import { CalculatingStep } from 'components/creatorFlow/steps/CalculatingStep';
import { PostStep } from 'components/creatorFlow/steps/PostStep';
import { PersonalImpactStep } from 'components/creatorFlow/steps/PersonalImpactStep';
import { routes } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { updateCurrentStep, creatorsState, resetCausesModal, showCausesModal } from 'store/creators/creators.slice';
import ModalComponent from 'pages/causes/modal';

export function CreatorFlowIntroPage() {
   // const { signupData } = useAppSelector(authState);
   const { t } = useTranslation();
   const [buttonYPos, setButtonYPos] = useState(287);
   const [prevStep, setPrevStep] = useState(0);
   const { currentStep, causesModal } = useAppSelector(creatorsState);
   const { showModal, modalText, modalTitle } = causesModal;
   const setCurrentStep = (step: number) => dispatch(updateCurrentStep(step));

   const finalStep = 10;
   const navigate = useNavigate();
   const dispatch = useAppDispatch();

   const theme = useTheme();
   const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
   const { selectedCausesList } = useAppSelector(creatorsState);

   const handleBack = () => {
      setPrevStep(currentStep);
      setCurrentStep(currentStep - 1);
   };

   const handleNext = () => {
      if (currentStep === 7 && selectedCausesList.length !== 3) {
         dispatch(
            showCausesModal({
               text: t('cuases.error.pick3', { maxCauses: Number(process.env.REACT_APP_MAX_CAUSES_SELECTION) }),
            })
         );
      } else if (currentStep === finalStep) {
         navigate(routes().loginEmail);
      } else {
         setPrevStep(currentStep);
         setCurrentStep(currentStep + 1);
      }
   };

   const handleAnimateFinish = () => {
      setPrevStep(0);
   };

   const getInitialPosition = (previousStep: number, currentStepNumber: number, currentComponenet: number) => {
      if (previousStep === 1 && currentStep === 2) return { opacity: 0 };
      if (previousStep === 2 && currentStep === 1) return { opacity: 1 };
      if (previousStep === currentComponenet) return { left: 0 };
      if (previousStep > currentStepNumber) return { left: '-390px' };
      return { left: '390px' };
   };

   const getAnimatedPosition = (previousStep: number, currentStepNumber: number, currentComponenet: number) => {
      if (previousStep === 1 && currentStep === 2) return { opacity: 1 };
      if (previousStep === 2 && currentStep === 1) return { opacity: 0 };
      if (currentStepNumber === currentComponenet) return { left: 0 };
      if (previousStep > currentStepNumber) return { left: '390px' };
      return { left: '-390px' };
   };

   const duration = 0.3;

   const handleButtonYPos = (yPos: number) => {
      setButtonYPos(yPos);
   };

   const [dialogOpen, setDialogOpen] = React.useState(false);

   const handleDialogClose = () => {
      setDialogOpen(false);
   };

   return (
      <div
         style={{
            width: '100%',
            height: '100%',
            position: 'relative',
         }}
      >
         {currentStep < 10 && (
            <Box
               sx={{
                  opacity: { xs: 0, sm: 1 },
                  display: { xs: 'none', sm: 'block' },
                  width: '390px',
                  height: '100%',
                  left: '412px',
                  position: 'absolute',
                  background: '#f7f7f7',
                  zIndex: 100,
               }}
            />
         )}
         {currentStep < 10 && (
            <Box
               sx={{
                  opacity: { xs: 0, sm: 1 },
                  width: '390px',
                  display: { xs: 'none', sm: currentStep === 1 ? 'none' : 'block' },
                  height: '100%',
                  left: '-412px',
                  position: 'absolute',
                  background: '#f7f7f7',
                  zIndex: 100,
               }}
            />
         )}
         {currentStep === 1 && <WelcomeStep isMobile={isMobile} onNext={handleNext} />}
         {(currentStep === 2 || prevStep === 2) && (
            <motion.div
               initial={getInitialPosition(prevStep, currentStep, 2)}
               animate={getAnimatedPosition(prevStep, currentStep, 2)}
               transition={{ duration }}
               onAnimationComplete={handleAnimateFinish}
               style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
               }}
            >
               <NameStep getHeight={(yPos) => handleButtonYPos(yPos)} />
            </motion.div>
         )}
         {(currentStep === 3 || prevStep === 3) && (
            <motion.div
               initial={getInitialPosition(prevStep, currentStep, 3)}
               animate={getAnimatedPosition(prevStep, currentStep, 3)}
               transition={{ duration }}
               onAnimationComplete={handleAnimateFinish}
               style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
               }}
            >
               <WelcomeUserAnimation getHeight={(yPos) => handleButtonYPos(yPos)} />
            </motion.div>
         )}
         {(currentStep === 4 || prevStep === 4) && (
            <motion.div
               initial={getInitialPosition(prevStep, currentStep, 4)}
               animate={getAnimatedPosition(prevStep, currentStep, 4)}
               transition={{ duration }}
               onAnimationComplete={handleAnimateFinish}
               style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
               }}
            >
               <SocialNetworkStep getHeight={(yPos) => handleButtonYPos(yPos)} />
            </motion.div>
         )}
         {(currentStep === 5 || prevStep === 5) && (
            <motion.div
               initial={getInitialPosition(prevStep, currentStep, 5)}
               animate={getAnimatedPosition(prevStep, currentStep, 5)}
               transition={{ duration }}
               onAnimationComplete={handleAnimateFinish}
               style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
               }}
            >
               <FollowersStep getHeight={(yPos) => handleButtonYPos(yPos)} />
            </motion.div>
         )}
         {(currentStep === 6 || prevStep === 6) && (
            <motion.div
               initial={getInitialPosition(prevStep, currentStep, 6)}
               animate={getAnimatedPosition(prevStep, currentStep, 6)}
               transition={{ duration }}
               onAnimationComplete={handleAnimateFinish}
               style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
               }}
            >
               <ViewsStep getHeight={(yPos) => handleButtonYPos(yPos)} />
            </motion.div>
         )}
         {(currentStep === 7 || prevStep === 7) && (
            <motion.div
               initial={getInitialPosition(prevStep, currentStep, 7)}
               animate={getAnimatedPosition(prevStep, currentStep, 7)}
               transition={{ duration }}
               onAnimationComplete={handleAnimateFinish}
               style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
               }}
            >
               <SocialIssuesStep getHeight={(yPos) => handleButtonYPos(yPos)} />
            </motion.div>
         )}
         {(currentStep === 8 || prevStep === 8) && (
            <motion.div
               initial={getInitialPosition(prevStep, currentStep, 8)}
               animate={getAnimatedPosition(prevStep, currentStep, 8)}
               transition={{ duration }}
               onAnimationComplete={handleAnimateFinish}
               style={{
                  position: 'absolute',
                  width: '100%',
                  height: '100%',
               }}
            >
               <PostStep getHeight={(yPos) => handleButtonYPos(yPos)} />
            </motion.div>
         )}
         {currentStep === 9 && (
            <motion.div
               initial={getInitialPosition(prevStep, currentStep, 9)}
               animate={getAnimatedPosition(prevStep, currentStep, 9)}
               transition={{ duration }}
               onAnimationComplete={handleAnimateFinish}
               style={{
                  position: 'absolute',
                  width: '100vw',
                  height: '100dvh',
               }}
            >
               <CalculatingStep isMobile={isMobile} onPrev={handleBack} onNext={handleNext} />
            </motion.div>
         )}
         {(currentStep === 10 || prevStep === 10) && <PersonalImpactStep onPrev={handleBack} onNext={handleNext} />}
         {currentStep > 1 && currentStep < 9 && (
            <motion.div
               style={{
                  position: 'absolute',
                  width: '100%',
                  marginTop: buttonYPos,
               }}
               animate={currentStep > 8 ? { opacity: 0 } : { marginTop: buttonYPos, opacity: 1 }}
               transition={{ duration }}
            >
               <Box
                  sx={{
                     position: { xs: 'fixed', sm: 'relative' },
                     background: '#f7f7f7',
                     bottom: { xs: 0 },
                     height: { xs: '98px', sm: 'unset' },
                     borderTop: { xs: '1px solid #00000016', sm: 'unset' },
                     display: 'flex',
                     justifyContent: 'space-between',
                     alignItems: 'center',
                     width: { xs: '100vw', sm: '100%' },
                     padding: { xs: '0px 30px', sm: '0px 0px' },
                     left: { xs: 0, sm: 'unset' },
                  }}
               >
                  <Button
                     sx={{ mt: { sm: 5.25 }, textTransform: 'none', justifyContent: 'center' }}
                     className='creatorFlow'
                     variant='text'
                     size='medium'
                     color='black'
                     onClick={handleBack}
                  >
                     Back
                  </Button>

                  <Button
                     sx={{ mt: { sm: 5.25 }, textTransform: 'none', justifyContent: 'end' }}
                     className='creatorFlow'
                     variant='contained'
                     size='medium'
                     color='black'
                     onClick={() => handleNext()}
                  >
                     Next
                  </Button>
               </Box>
            </motion.div>
         )}

         {showModal && (
            <ModalComponent title={modalTitle} content={modalText} onClose={() => dispatch(resetCausesModal())} open />
         )}

         <Dialog open={dialogOpen} onClose={handleDialogClose}>
            <DialogTitle>Alert</DialogTitle>
            <DialogContent>
               <DialogContentText>Please type your name.</DialogContentText>
            </DialogContent>
            <DialogActions>
               <Button onClick={handleDialogClose}>OK</Button>
            </DialogActions>
         </Dialog>
      </div>
   );
}
