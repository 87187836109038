import { Box, Button, Typography } from '@mui/material';
import { AnimatePresence, motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { Pagination } from 'swiper/modules';
import { creatorsState, getSocialImapactIndicatorForUser } from 'store/creators/creators.slice';
import { useAppDispatch, useAppSelector } from 'store/hooks';

import 'swiper/css';
import 'swiper/css/pagination';

export interface StepComponentProps {
   onNext: () => void;
   onPrev?: () => void;
}

export const colors = {
   button: '#B415FF',
} as const;

export function PersonalImpactStep({ onNext, onPrev }: StepComponentProps): React.ReactElement {
   const { calculatedIdicators } = useAppSelector(creatorsState);
   const dispatch = useAppDispatch();

   useEffect(() => {
      if (calculatedIdicators == null) {
         dispatch(getSocialImapactIndicatorForUser());
      }
   }, [calculatedIdicators]);

   return (
      <Box
         sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            paddingTop: '40px',
            gap: 3.75,
         }}
      >
         <Typography sx={{ fontWeight: 900, lineHeight: '38px' }} variant='h4' component='h1'>
            Your personal yearly impact could be:
         </Typography>
         <Box
            sx={{
               width: '310px',
               height: '350px',
               display: { xs: 'block', sm: 'none' },
               '& .swiper': {
                  height: '100%',
                  '& .swiper-pagination': {
                     bottom: 0,
                  },
               },
            }}
         >
            <Swiper modules={[Pagination]} spaceBetween={50} slidesPerView={1} loop pagination>
               {calculatedIdicators?.map((item) => (
                  <SwiperSlide key={item.id}>
                     <Box
                        sx={{
                           position: 'relative',
                           width: { xs: '310px', sm: '220px' },
                           height: { xs: '310px', sm: '220px' },
                        }}
                     >
                        <Box
                           sx={{
                              width: '100%',
                              height: '100%',
                              borderRadius: '10px',
                              position: 'absolute',
                           }}
                           src={item.cuaseImage}
                           alt={item.cuaseName}
                           component='img'
                        />
                        <Box
                           sx={{
                              position: 'absolute',
                              background: item.cuasesColor,
                              color: '#fff',
                              zIndex: '2',
                              top: '15px',
                              left: '15px',
                              padding: '2px 8px',
                              borderRadius: '2px',
                              fontWeight: '700',
                              fontSize: '12px',
                              lineHeight: '18px',
                           }}
                        >
                           {item.cuaseName}
                        </Box>
                        <Typography
                           sx={{
                              position: 'absolute',
                              color: '#fff',
                              zIndex: '2',
                              bottom: '15px',
                              left: '15px',
                              fontWeight: '700',
                              fontSize: { xs: '36px', sm: '24px' },
                              lineHeight: { xs: '36px', sm: '24px' },
                           }}
                        >
                           {item.text}
                        </Typography>
                        <Box
                           sx={{
                              width: '100%',
                              height: '100%',
                              borderRadius: '10px',
                              position: 'absolute',
                              zIndex: '1',
                              background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%)',
                           }}
                        />
                     </Box>
                  </SwiperSlide>
               ))}
            </Swiper>
         </Box>
         <Box
            sx={{
               width: '100%',
               gap: '20px',
               height: '220px',
               display: { sm: 'flex', xs: 'none' },
               justifyContent: 'center',
            }}
         >
            {calculatedIdicators?.map((item) => (
               <Box
                  key={item.id}
                  sx={{
                     position: 'relative',
                     minWidth: { xs: '310px', sm: '220px' },
                     height: { xs: '310px', sm: '220px' },
                  }}
               >
                  <Box
                     sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '10px',
                        position: 'absolute',
                     }}
                     src={item.cuaseImage}
                     alt={item.cuaseName}
                     component='img'
                  />
                  <Box
                     sx={{
                        position: 'absolute',
                        background: item.cuasesColor,
                        color: '#fff',
                        zIndex: '2',
                        top: '15px',
                        left: '15px',
                        padding: '2px 8px',
                        borderRadius: '2px',
                        fontWeight: '700',
                        fontSize: '12px',
                        lineHeight: '18px',
                     }}
                  >
                     {item.cuaseName}
                  </Box>
                  <Typography
                     sx={{
                        position: 'absolute',
                        color: '#fff',
                        zIndex: '2',
                        bottom: '15px',
                        left: '15px',
                        fontWeight: '700',
                        fontSize: { xs: '36px', sm: '24px' },
                        lineHeight: { xs: '36px', sm: '24px' },
                     }}
                  >
                     {item.text}
                  </Typography>
                  <Box
                     sx={{
                        width: '100%',
                        height: '100%',
                        borderRadius: '10px',
                        position: 'absolute',
                        zIndex: '1',
                        background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0.8) 100%)',
                     }}
                  />
               </Box>
            ))}
         </Box>

         <Box
            sx={{
               position: { xs: 'fixed', sm: 'relative' },
               bottom: { xs: 0 },
               height: { xs: '98px', sm: 'unset' },
               borderTop: { xs: '1px solid #00000016', sm: 'unset' },
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               width: { xs: '100%', sm: '100%' },
            }}
         >
            <Button
               sx={{
                  textTransform: 'none',
                  mt: { sm: 5.25, width: '100%' },
                  mx: { xs: '30px', sm: 'unset' },
                  justifyContent: 'center',
               }}
               className='creatorFlow'
               variant='contained'
               size='large'
               color='black'
               onClick={onNext}
            >
               {`Let's Start ->`}
            </Button>
         </Box>
      </Box>
   );
}
