import React from 'react';
import { styled, Box, BoxProps, Typography, TypographyProps } from '@mui/material';

export const CauseBoxItem = styled(({ children, ...props }: BoxProps) => (
   <Box sx={{ width: { xs: '100%', sm: '350px' } }} component='div' borderRadius={2.5} {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   maxWidth: '350px',
   display: 'flex',
   flexDirection: 'column',
   overflow: 'hidden',
   background: theme.palette.white.main,
   border: `1px solid #0000001A`,
   boxSizing: 'border-box',
}));

export const CauseTitleWrapper = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' display='flex' gap={1.375} alignItems='center' pl={2.5} pr={2.5} pt={2} pb={2} {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   flex: 1,
   borderBottom: `1px solid #0000001A`,
   backgroundColor: theme.palette.gray.backBtnBG,
   display: 'flex',
   justifyContent: 'space-between',
}));

export const CharitiesWrapper = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   display: 'flex',
   flexDirection: 'column',
}));

export const CharityItemBox = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' tabIndex={0} width='50px' height='40px' {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   '&:nth-of-type(1)': {
      justifySelf: 'start',
   },
   '&:nth-of-type(2)': {
      justifySelf: 'center',
   },
   '&:nth-of-type(3)': {
      justifySelf: 'end',
   },
   '&:nth-of-type(4)': {
      justifySelf: 'start',
   },
   '&:nth-of-type(5)': {
      justifySelf: 'center',
   },
   '&:nth-of-type(6)': {
      justifySelf: 'end',
   },
   '&:nth-of-type(7)': {
      justifySelf: 'start',
   },
   '&:nth-of-type(8)': {
      justifySelf: 'center',
   },
   '&:nth-of-type(9)': {
      justifySelf: 'end',
   },
}));

export const CauseTitle = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='h6' fontSize={14} {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   fontWeight: 700,
   textTransform: 'capitalize',
   lineHeight: '23px',
   fontSize: '19px',
   color: theme.palette.black.main,
}));

export const CharitiesSelected = styled(({ children, ...props }: TypographyProps) => (
   <Typography component='span' fontSize={14} {...props}>
      {children}
   </Typography>
))(({ theme }) => ({
   fontWeight: 500,
   fontSize: '16px',
   lineHeight: '19px',
   color: theme.palette.gray.sixGray,
}));
