import React from 'react';

export function LoadingIcon() {
   return (
      <svg
         className='rotating'
         xmlns='http://www.w3.org/2000/svg'
         width='25'
         height='24'
         fill='none'
         viewBox='0 0 25 24'
      >
         <g clipPath='url(#clip0_3344_5532)'>
            <path fill='#fff' d='M.5 12c0 6.627 5.373 12 12 12s12-5.373 12-12h-3a9 9 0 11-18 0h-3z' />
         </g>
         <defs>
            <clipPath id='clip0_3344_5532'>
               <path fill='#fff' d='M0 0H24V24H0z' transform='translate(.5)' />
            </clipPath>
         </defs>
      </svg>
   );
}
