import { Box, Button, Typography } from '@mui/material';

import React, { HTMLAttributes, ReactElement, useEffect, useRef, useState } from 'react';
import { addSelectedNetworks } from 'store/auth/auth.slice';
import { useAppDispatch } from 'store/hooks';
import { ReactComponent as InstagramIcon } from '../icons/instagram.svg';
import { ReactComponent as TiktokIcon } from '../icons/tiktok.svg';
import { ReactComponent as TwitchIcon } from '../icons/twitch.svg';
import { ReactComponent as TwitterIcon } from '../icons/twitter.svg';
import { ReactComponent as YoutubeIcon } from '../icons/youtube.svg';
import { ReactComponent as SnapchatIcon } from '../icons/snapchat.svg';
import { ReactComponent as SpotifyIcon } from '../icons/spotify.svg';
import { ReactComponent as FacebookIcon } from '../icons/facebook.svg';
import { ReactComponent as PinterestIcon } from '../icons/pinterest.svg';

export interface StepComponentProps {
   getHeight: (height: number) => void;
}

export const colors = {
   button: '#B415FF',
} as const;

const socialNetworks = [
   { id: 'instangram', name: 'Instagram', icon: <InstagramIcon /> },
   { id: 'tiktok', name: 'TikTok', icon: <TiktokIcon /> },
   { id: 'twitch', name: 'Twitch', icon: <TwitchIcon /> },
   { id: 'twitter', name: 'X (Twitter)', icon: <TwitterIcon /> },
   { id: 'youtube', name: 'Youtube', icon: <YoutubeIcon /> },
   { id: 'snapchat', name: 'Snapchat', icon: <SnapchatIcon /> },
   { id: 'spotify', name: 'Spotify', icon: <SpotifyIcon /> },
   { id: 'facebook', name: 'Facebook', icon: <FacebookIcon /> },
   { id: 'pinterest', name: 'Pinterest', icon: <PinterestIcon /> },
];

interface SocialNetworkCardProps {
   id: string;
   name: string;
   icon: any;
   selected?: boolean;
   onClick: () => void;
}

function SocialNetworkCard({
   id,
   name,
   icon,
   selected,
   onClick,
}: SocialNetworkCardProps): ReactElement<HTMLButtonElement> {
   return (
      <Box
         onClick={onClick}
         sx={{
            background: '#fff',
            borderRadius: '8px',
            padding: { xs: '16px 8px', sm: '16px 8px 16px 16px' },
            display: 'flex',
            cursor: 'pointer',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'start',
            gap: '10px',
            width: '100%',
            border: selected ? '2px solid #333' : '2px solid #fff',
         }}
      >
         {icon}
         <Typography
            sx={{
               color: '#333',
               fontWeight: '600',
               fontSize: { xs: '14px', sm: '16px' },
               lineHeight: '19.36px',
            }}
         >
            {name}
         </Typography>
      </Box>
   );
}

export function SocialNetworkStep({ getHeight }: StepComponentProps): React.ReactElement {
   const [selectedNetworks, setSelectedNetworks] = useState<Array<string>>([]);
   const containerRef = useRef<HTMLDivElement | null>(null);
   const [totalHeight, setTotalHeight] = useState(0);
   const dispatch = useAppDispatch();

   useEffect(() => {
      if (containerRef.current) {
         const { height } = containerRef.current.getBoundingClientRect();
         setTotalHeight(height);
         getHeight(height);
      }
   }, []); // Runs once on mount.

   useEffect(() => {
      dispatch(addSelectedNetworks(selectedNetworks));
   }, [selectedNetworks]);

   return (
      <Box
         ref={containerRef}
         sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            paddingTop: '40px',
            gap: 3.75,
         }}
      >
         <Typography sx={{ fontWeight: 900, lineHeight: '38px' }} variant='h4' component='h1'>
            What are your active social networks?
         </Typography>
         <Box
            sx={{
               display: 'grid',
               gridTemplateColumns: {
                  xs: '95px 95px 95px',
                  sm: '110px 110px 110px',
               },
               gridTemplateRows: '90px 90px 90px',
               gap: '10px',
            }}
         >
            {socialNetworks.map((item: any) => {
               return (
                  <SocialNetworkCard
                     onClick={() => {
                        if (selectedNetworks.includes(item.id)) {
                           setSelectedNetworks([...selectedNetworks.filter((v) => v !== item.id)]);
                        } else {
                           setSelectedNetworks([...selectedNetworks, item.id]);
                        }
                     }}
                     key={item.id}
                     {...item}
                     selected={selectedNetworks.includes(item.id)}
                  />
               );
            })}
         </Box>
      </Box>
   );
}
