/* eslint-disable import/no-extraneous-dependencies */
import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
// import Backend from 'i18next-http-backend';
import Backend from 'i18next-locize-backend';

const locizeBackendOptions = {
   projectId: process.env.REACT_APP_LOCIZE_PROJECT_ID,
   apiKey: process.env.REACT_APP_LOCIZE_API_KEY,
   fallbackLng: 'en',
   interpolation: { escapeValue: false },
   react: { useSuspense: true },
   load: 'languageOnly',
   version: process.env.REACT_APP_LOCIZE_VERSION,
};

i18next
   .use(initReactI18next)
   .use(Backend)
   .init({
      lng: 'en',
      ns: ['common', 'global', 'israel', 'widgets', 'helene'],
      defaultNS: process.env.REACT_APP_LANG,
      backend: locizeBackendOptions,
   });

export default i18next;
