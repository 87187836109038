export const TITLE_STYLE = {
   fontFamily: 'Figtree',
   fontWeight: 700,
   fontSize: '32px',
   lineHeight: '38px',
   justifyContent: 'center',
};

export const NAV_BG_COLOR = '#EFCAC9';
export const MAIN_CONTAINER_WIDTH = '390px';
