import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { getBase64FromUrl } from 'utils/utils';
import type { RootState } from 'store';

import { fetchWidgets } from './widget.services';
import type { WidgetFormat, WidgetInfoTypes, WidgetSliceTypes } from './types';

export const getWidgetList = createAsyncThunk('causes/getCausesList', async (_, { rejectWithValue, dispatch }) => {
   try {
      const response = await fetchWidgets();
      dispatch(storeReceivedWidgets(response.data));
      return response.data;
   } catch (error) {
      if (error instanceof Error) {
         return rejectWithValue(error);
      }
      return error;
   }
});

const initialState: WidgetSliceTypes = {
   widgetList: [],
};

export const widgetSlice = createSlice({
   name: 'widgetSlice',
   initialState,
   reducers: {
      storeReceivedWidgets(state, action: PayloadAction<WidgetInfoTypes>) {
         const { data } = action.payload;
         return {
            ...state,
            widgetList: data,
         };
      },
   },
});

export const { storeReceivedWidgets } = widgetSlice.actions;

export const widgetState = (state: RootState) => state.widget;
