import { Box, ImageList, ImageListItem, useMediaQuery } from '@mui/material';
import React from 'react';
import { IMAGE } from 'enums/images';

const {
   WELCOME_PAGE_IMG01,
   WELCOME_PAGE_IMG02,
   WELCOME_PAGE_IMG03,
   WELCOME_PAGE_IMG04,
   WELCOME_PAGE_IMG05,
   WELCOME_PAGE_IMG06,
   WELCOME_PAGE_IMG07,
   WELCOME_PAGE_IMG08,
   WELCOME_PAGE_IMG09,
   WELCOME_PAGE_IMG10,
   WELCOME_PAGE_IMG11,
   WELCOME_PAGE_IMG12,
   WELCOME_PAGE_IMG13,
   WELCOME_PAGE_IMG14,
} = IMAGE;

const pictures = [
   WELCOME_PAGE_IMG01,
   WELCOME_PAGE_IMG02,
   WELCOME_PAGE_IMG03,
   WELCOME_PAGE_IMG04,
   WELCOME_PAGE_IMG05,
   WELCOME_PAGE_IMG06,
   WELCOME_PAGE_IMG07,
   WELCOME_PAGE_IMG08,
   WELCOME_PAGE_IMG09,
   WELCOME_PAGE_IMG10,
   WELCOME_PAGE_IMG11,
   WELCOME_PAGE_IMG12,
   WELCOME_PAGE_IMG13,
   WELCOME_PAGE_IMG14,
];

const WelcomePageCells = () => {
   const isMobile = useMediaQuery('(max-width:600px)');
   const cols = isMobile ? 7 : 14;
   return (
      <Box
         sx={{
            width: '100vw',
            height: { xs: '226px', sm: '110px' },
            display: 'flex',
            justifyContent: 'center',
            overflowX: 'hidden',
            overflowY: 'hidden',
         }}
      >
         <ImageList
            cols={cols}
            gap={6}
            sx={{
               justifyContent: 'center', // Center items within the ImageList
               flexWrap: 'nowrap', // Prevent items from wrapping if needed
               overflowX: 'hidden',
               overflowY: 'hidden',
            }}
         >
            {pictures.map((item, index) => (
               <ImageListItem
                  sx={{
                     width: '110px',
                     height: '110px',
                     borderRadius: '6px',
                     overflow: 'hidden',
                     border: index % 2 !== 0 ? '1px solid rgba(0,0,0,0.1)' : 'none',
                     boxSizing: 'border-box',
                  }}
                  key={item}
               >
                  <img src={item} alt={item} loading='lazy' />
               </ImageListItem>
            ))}
         </ImageList>
      </Box>
   );
};

export default WelcomePageCells;
