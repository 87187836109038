import React from 'react';
import { Box, BoxProps, styled, IconButton, IconButtonProps } from '@mui/material';

export const CenteredWrapper = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   width: '100%',
   textAlign: 'left',
   display: 'flex',
   flexDirection: 'column',
}));

export const ActionItemsWrapper = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   width: '100%',
   display: 'flex',
   alignItems: 'center',
   justifyContent: 'space-between',
   marginBottom: '30px',
   marginTop: '30px',
}));

export const IconWrapper = styled(({ children, ...props }: IconButtonProps) => (
   <IconButton component='div' {...props}>
      {children}
   </IconButton>
))(({ theme }) => ({
   width: theme.typography.pxToRem(48),
   height: theme.typography.pxToRem(48),
   background: theme.palette.black.main,
   border: `1px solid ${theme.palette.white.main}`,
   '&:hover': {
      background: theme.palette.black.main,
   },
   '&.MuiIconButton-root.Mui-disabled': {
      opacity: 0.6,
      background: theme.palette.gray.sixGray,
   },
}));

export const AmountBox = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   maxWidth: '234px',
   width: '100%',
   height: '60px',
   background: theme.palette.white.main,
   border: `2px solid ${theme.palette.black.main}`,
   display: 'flex',
   justifyContent: 'center',
   alignItems: 'center',
   borderRadius: '4px',
   marginLeft: '10px',
   marginRight: '10px',
}));
