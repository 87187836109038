import api from 'api';

// import type { CauseTypes } from './types';

interface SocailImpactIndicatorDto {
   socialNetworks: string[];
   viewCount: number;
   cuases: number[];
   followersCount: number;
   amountOfPosts: number;
}

export const getSocialImapactIndicator = (data: SocailImpactIndicatorDto) => {
   return api.post(`/v1/impactIndicator/socialImapactIndicator`, {
      ...data,
   });
};
