import api from 'api';

import type { CauseTypes } from './types';

export const fetchCauses = () => {
   return api.get('/v1/charity/causes?skip=0&take=30');
};

export const sendSelectedCauses = (causes: CauseTypes[]) => {
   const arrayOfIds = causes.map((cause) => cause.id).join(',');
   return api.post(`/v1/users/me/causes/ids=${arrayOfIds}`);
};

export const sendSingleCause = (id: number) => {
   return api.post(`/v1/users/me/causes/${id}`);
};

export const getSelectedCauses = () => {
   return api.get('/v1/users/me/causes');
};

export const getCharities = () => {
   return api.get('/v1/users/me/charities');
};

export const getCharitiesBySelectedCauses = () => {
   return api.get('/v1/users/me/charities/suggest');
};

export const getCharitiesBySelectedCausesAnonymous = (selectedCausesList: number[], referralCode?: string) => {
   return api.post('/v1/charity/causes/suggest', { causes: selectedCausesList, referral: referralCode });
};

export const setSelectedCharities = async (charityIds: number[]) => {
   const charities = charityIds.join(',');
   await api.post(`/v1/users/me/charities/ids=${charities}`);
};
