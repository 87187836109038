import React from 'react';

type IconProps = {
   iconName: string;
   iconColor?: string;
   iconSizeVariant?: 'small' | 'medium';
};
const DynamicGoogleIcon = ({ iconName, iconColor, iconSizeVariant }: IconProps) => {
   const getIconSize = (): number => {
      if (!iconSizeVariant) return 24;
      return iconSizeVariant === 'small' ? 16 : 24;
   };
   return (
      <span
         style={{
            fontSize: `${getIconSize()}px`,
            color: iconColor || '#333333',
            fontVariationSettings: `'FILL': 0, 'wght': 400, 'GRAD': 0, 'opsz': 24,`,
         }}
         className='material-symbols-rounded'
      >
         {iconName.toLowerCase()}
      </span>
   );
};

export default DynamicGoogleIcon;
