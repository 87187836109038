import React from 'react';
import { styled, Box, BoxProps } from '@mui/material';

export const ContentWrapper = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   width: '100%',
   height: '100%',
   display: 'flex',
   alignItems: 'center',
   flexDirection: 'column',
   paddingTop: theme.typography.pxToRem(theme.size.font_48),
   paddingBottom: theme.typography.pxToRem(theme.size.font_22),
   justifyContent: 'space-between',
}));

export const GenderItemsBox = styled(({ children, ...props }: BoxProps) => (
   <Box component='div' {...props}>
      {children}
   </Box>
))(({ theme }) => ({
   width: '100%',
   display: 'flex',
   flexDirection: 'column',
   gap: theme.typography.pxToRem(theme.size.font_20),
   padding: `0 ${theme.typography.pxToRem(theme.size.font_48)}`,
}));
