import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';

import { useGoogleLogin } from '@react-oauth/google';
import { Trans, useTranslation } from 'react-i18next';
import { GoogleIcon, Icon } from 'components/icon';
import { ENUM_ICON } from 'enums/icons';
import {
   PageMainLabel,
   PageSubLabel,
   ButtonFurther,
   ContentWrapper,
   BottomContentBox,
   HaveLinkText,
   DividerComponent,
   BasicButton,
   LoadingIcon,
   RegLinkText,
} from 'components/common';
import PasswordTextField from 'components/password-textfield';
import { routes } from 'routes/routes';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { detectWebview, isValidEmail } from 'utils/utils';
import { PageWrapper } from 'wrappers';
import {
   addEmail,
   addName,
   addPassword,
   authState,
   createAnonymousSignup,
   fetchLoggedUser,
   fetchVerificationCode,
   loginUserWithGoogle,
   loginUserWithGoogleForAnonymous,
   sendMagicLink,
} from 'store/auth/auth.slice';
import { LoggedUserTypes } from 'store/auth/types';
import { BasicModal } from 'modals/basic.modal';
import { commonState } from 'store/common/common.slice';
import {
   handleSelectCause,
   handleSendSelectedCauses,
   fetchCharitiesBySelectedCauses,
   causesState,
} from 'store/causes/causes.slice';
import { CauseTypes } from 'store/causes/types';

export const SignUpPage = () => {
   const navigate = useNavigate();
   const dispatch = useAppDispatch();
   const inputRef = useRef<HTMLDivElement | null>(null);
   const { signupData } = useAppSelector(authState);
   const { fetchVerCodeError, sendNewUserWithCodeError } = useAppSelector(authState);
   const { project } = useAppSelector(commonState);
   const [email, setEmail] = useState('');
   // const [alert, setAlert] = useState(false);
   const [alertName, setAlertName] = useState(false);
   const [name, setName] = useState('');
   const [error, setError] = useState(false);
   const [dialog, setDialog] = useState(false);
   const [submitting, setSubmitting] = useState(false);
   const { causesList, selectedCausesList } = useAppSelector(causesState);
   const [step, setStep] = useState<'email' | 'name'>('email');

   const { t } = useTranslation();

   const isEmailStep = step === 'email';
   const mainLabelText = isEmailStep ? 'Enter your email' : "What's your name?";
   const isButtonDisabled = submitting || error || (isEmailStep ? email.length === 0 : name.length === 0);
   const inputPlaceholder = isEmailStep ? 'Email address' : 'Your first name';
   const buttonText = isEmailStep ? 'Next' : 'Create account';
   const errorMessage = isEmailStep ? t('saveYourPortfolio.emailErrorMessage') : t('signUp.nameErrorMessage');

   const handleInputOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (step === 'email') {
         // setAlert(false);
         const newEmail = e.target.value;
         setEmail(newEmail);
      } else {
         const newName = e.target.value;
         setName(newName);
         dispatch(addName(newName));
      }
   };

   const handleNext = async () => {
      if (submitting) return;
      if (step === 'email') {
         if (!isValidEmail(email)) {
            console.log('Email is not valid');
            setError(true);
            return;
         }
         dispatch(addEmail(email));
         const res = await dispatch(sendMagicLink());
         if (res?.payload?.userFound) {
            navigate(routes().checkYourEmail);
         } else {
            setStep('name');
         }
      } else if (name.length >= 3) {
         setError(false);
         setAlertName(false);
         setSubmitting(true);
         dispatch(addName(name));

         dispatch(createAnonymousSignup())
            .then(({ payload }) => {
               if (payload.response && payload.response?.status === 409) {
                  dispatch(sendMagicLink()).then((res) => {
                     navigate(routes().checkYourEmail);
                  });
               } else if (payload.user) {
                  if (payload.user.details.creatorCoupon) {
                     navigate(`${routes().creatorFlow.base}/${routes().creatorFlow.creatorImpact}`);
                  } else if (project === 'helene') {
                     // Set cuases hard coded
                     const causeHeleneCause: CauseTypes = {
                        id: 11,
                        title: 'Helene',
                        description: '',
                        active: true,
                        icon: '',
                        shortDescription: '',
                        order: 1,
                        impactImage: '',
                        impactHeader: '',
                        impactBackground: '',
                     };
                     dispatch(handleSelectCause(causeHeleneCause));
                     const causeHeleneCause2: CauseTypes = {
                        ...causeHeleneCause,
                        id: 8,
                     };
                     dispatch(handleSelectCause(causeHeleneCause2));
                     const causeHeleneCause3: CauseTypes = {
                        ...causeHeleneCause,
                        id: 7,
                     };
                     dispatch(handleSelectCause(causeHeleneCause3));

                     dispatch(handleSendSelectedCauses()).then(() => {
                        dispatch(fetchCharitiesBySelectedCauses()).then(() => {
                           localStorage.setItem('selectedCauses', JSON.stringify(selectedCausesList));
                           navigate(routes().generatePage);
                        });
                     });
                     navigate(routes().generatePage);
                  } else {
                     navigate(routes().causes);
                  }
               } else {
                  dispatch(addEmail(''));
                  dispatch(addName(''));
               }
            })
            .finally(() => {
               setSubmitting(false);
            });
      } else {
         setError(true);
      }
   };

   useEffect(() => {
      const handleKeyPress = (event: KeyboardEvent) => {
         if (event.key === 'Enter') {
            event.preventDefault();
            handleNext();
         }
      };
      if (inputRef && inputRef.current) {
         inputRef.current.addEventListener('keypress', handleKeyPress);
      }

      return () => {
         if (inputRef && inputRef.current) {
            inputRef.current.removeEventListener('keypress', handleKeyPress);
         }
      };
   }, [email]);

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            px: { xs: '20px', sm: 0 },
            mb: { xs: '52px', sm: 0 },
         }}
      >
         <ContentWrapper
            sx={{
               maxWidth: '390px',
               justifyContent: 'start',
            }}
         >
            <Box component='div' width='100%' display='flex' flexDirection='column' mb='30px'>
               <PageMainLabel mb='20px'>{mainLabelText}</PageMainLabel>

               <TextField
                  fullWidth
                  required
                  className='CommonInput'
                  value={isEmailStep ? email : name}
                  type='text'
                  ref={inputRef}
                  onFocus={() => setError(false)}
                  onChange={handleInputOnChange}
                  error={error}
                  placeholder={inputPlaceholder}
               />
               {error && (
                  <Typography sx={{ mt: '4px', textAlign: { sm: 'center' }, color: '#F55D66' }}>
                     {errorMessage}
                  </Typography>
               )}
            </Box>

            <BottomContentBox isFocused={false} focusedValue='calc(-100% + 642px)'>
               <Button disabled={isButtonDisabled} onClick={handleNext} variant='primaryBtn'>
                  <Typography>{buttonText}</Typography>
               </Button>
               {project === 'helene' ? (
                  <RegLinkText sx={{ mt: { xs: '28px', sm: '28px' } }}>
                     {t('clickTC')}
                     <Link to={`${process.env.REACT_APP_MAIN_BASE_URL}/tc/`} target='_blank'>
                        <span style={{ minWidth: 'max-content' }}>{t('terms')}</span>
                     </Link>{' '}
                     and{' '}
                     <Link to={`${process.env.REACT_APP_MAIN_BASE_URL}/privacy-policy/`} target='_blank'>
                        <span style={{ minWidth: 'max-content' }}>{t('privacyPolicy')}</span>
                     </Link>
                     <div>
                        <Trans
                           i18nKey='tcSupport'
                           // eslint-disable-next-line jsx-a11y/anchor-has-content, jsx-a11y/control-has-associated-label, react/self-closing-comp
                           components={{ mailLink: <a href='mailto:support@proisrael.co'></a> }}
                        />
                     </div>
                  </RegLinkText>
               ) : (
                  ''
               )}
            </BottomContentBox>
         </ContentWrapper>
      </Box>
   );
};
