/* eslint-disable no-console */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import { Box, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes/routes';
import { PageWrapper } from 'wrappers';
import { IMAGE } from 'enums/images';
import { PageMainLabel, PageSubLabel } from 'components/common';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { authState, fetchUserByAccessToken } from 'store/auth/auth.slice';
import { LoggedUserDataTypes } from 'store/auth/types';
import { detectWebview } from 'utils/utils';

const textList = ['Welcome Back', 'We are loading your profile', 'Few more seconds...'];

export function MobileRedirectPage() {
   const navigate = useNavigate();
   const generatingProfileTime = Number(process.env.REACT_APP_TEXT_GENERATING_INTERVAL) || 2000;
   const screenSE = useMediaQuery('(max-height:700px)');
   const location = useLocation();
   const { t } = useTranslation();
   const [currentText, setCurrentText] = useState(0);
   document.title = `Mobile Redirect | ${t('siteTitleName')}`;

   useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const redirectDeepLink = searchParams.get('url');
      if (redirectDeepLink != null) {
         const appUrl = decodeURI(redirectDeepLink);
         if (appUrl) {
            console.log('Redirecting to app:', appUrl);
            window.location.replace(`so.bono.app://${appUrl}`);
         }
      }
   }, [location.pathname]);

   return (
      <PageWrapper logo={false} logoSpace={!screenSE}>
         <Box
            component='section'
            display='flex'
            flexDirection='column'
            width='100%'
            paddingBottom='50%'
            mt='110px'
            justifyContent='center'
         >
            <Box component='div' width='100%' display='flex' flexDirection='column' textAlign='center'>
               <Box component='div' mb={3.75}>
                  <Box
                     component='img'
                     src={IMAGE.SVG_LOGO}
                     alt='BONO'
                     height='52px'
                     sx={{ width: '77px !important' }}
                  />
               </Box>
               <Box> Thank you for the payment</Box>
               <Box> Redirecting to app.....</Box>
            </Box>
         </Box>
      </PageWrapper>
   );
}
