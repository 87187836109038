import React from 'react';

const GoogleIcon = () => {
   return (
      <svg width='21' height='20' viewBox='0 0 21 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <g clipPath='url(#clip0_13680_18672)'>
            <path
               d='M10.4997 3.95833C11.9747 3.95833 13.2956 4.46667 14.3372 5.45833L17.1914 2.60417C15.4581 0.991667 13.1956 0 10.4997 0C6.59141 0 3.21224 2.24167 1.56641 5.50833L4.89141 8.0875C5.67891 5.71667 7.89141 3.95833 10.4997 3.95833Z'
               fill='#EA4335'
            />
            <path
               d='M20.075 10.2279C20.075 9.5737 20.0125 8.94036 19.9167 8.33203H10.5V12.0904H15.8917C15.65 13.3237 14.95 14.3737 13.9 15.082L17.1208 17.582C19 15.8404 20.075 13.2654 20.075 10.2279Z'
               fill='#4285F4'
            />
            <path
               d='M4.8875 11.912C4.6875 11.3078 4.57083 10.6661 4.57083 9.99948C4.57083 9.33281 4.68333 8.69115 4.8875 8.08698L1.5625 5.50781C0.883333 6.85781 0.5 8.38281 0.5 9.99948C0.5 11.6161 0.883333 13.1411 1.56667 14.4911L4.8875 11.912Z'
               fill='#FBBC05'
            />
            <path
               d='M10.5 20.0018C13.2 20.0018 15.4708 19.1143 17.1208 17.581L13.9 15.081C13.0042 15.6852 11.85 16.0393 10.5 16.0393C7.89167 16.0393 5.67917 14.281 4.8875 11.9102L1.5625 14.4893C3.2125 17.7602 6.59167 20.0018 10.5 20.0018Z'
               fill='#34A853'
            />
         </g>
         <defs>
            <clipPath id='clip0_13680_18672'>
               <rect width='20' height='20' fill='white' transform='translate(0.5)' />
            </clipPath>
         </defs>
      </svg>
   );
};

export default GoogleIcon;
