import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { Img } from 'react-image';
import { BackIconButton, ContentWrapper } from 'components/common';
import { routes } from 'routes/routes';
import { causesState } from 'store/causes/causes.slice';
import type { CharityTypes } from 'store/causes/types';
import { useAppSelector } from 'store/hooks';

import DynamicGoogleIcon from 'components/dynamic-icon/dynamicGoogleIcon';

export const CharityPage = () => {
   const navigate = useNavigate();
   const { pathname } = useLocation();
   const { charityList } = useAppSelector(causesState);
   const { t } = useTranslation();

   const [currentCharity, setCurrentCharity] = useState<CharityTypes | null>(null);

   const getBaseUrl = (url: string) => {
      const regex = /^(https?:\/\/[^/]+)\/.*/;
      const match = url.trim().match(regex);

      if (match) {
         const baseUrl = match[1];
         return baseUrl;
      }
      return '';
   };

   useEffect(() => {
      const regex = /(\d+)$/;
      const match = pathname.match(regex);
      if (match) {
         const lastNumber = Number(match[1]);
         const selected = charityList.find((charity) => charity.id === lastNumber);
         if (selected) {
            setCurrentCharity(selected);
         } else {
            navigate(routes().portfolio);
         }
      }
   }, [pathname]);

   useEffect(() => {
      document.title = `Charity Details | ${t('siteTitleName')}`;
   }, []);

   return (
      <ContentWrapper
         sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            width: '100%',
         }}
      >
         {/* Close button */}
         <BackIconButton
            sx={{
               position: 'absolute',
               top: '30px',
               left: '20px',
               zIndex: '10000',
            }}
            onClick={() => {
               navigate(routes().portfolio);
            }}
         >
            <DynamicGoogleIcon iconColor='#999999' iconName='arrow_back' />
         </BackIconButton>

         <Img
            style={{
               width: '100%',
               aspectRatio: 'auto',
            }}
            src={currentCharity?.image || ''}
            alt='Charity Main image'
            loader={
               <svg xmlns='http://www.w3.org/2000/svg' width='100%' height='200px' viewBox='0 0 180.119 139.794'>
                  <g paintOrder='fill markers stroke' transform='translate(-13.59 -66.639)'>
                     <path fill='#d0d0d0' d='M13.591 66.639H193.71v139.794H13.591z' />
                     <path
                        fill='#fff'
                        d='M118.507 133.514l-34.249 34.249-15.968-15.968-41.938 41.937h152.374z'
                        opacity='0.675'
                     />
                     <circle cx='58.217' cy='108.555' r='11.773' fill='#fff' opacity='0.675' />
                     <path fill='none' d='M26.111 77.634h152.614v116.099H26.111z' />
                  </g>
               </svg>
            }
         />
         <Box
            sx={{
               width: '100%',
               px: '20px',
            }}
         >
            <Box
               sx={{
                  marginTop: '-34px',
                  marginBottom: '10px',
                  width: 80,
                  height: 80,
                  borderRadius: '6px',
                  overflow: 'hidden',
                  border: '1px solid #999999',
                  boxSizing: 'border-box',
               }}
            >
               <img alt='Charity Logo' src={currentCharity?.icon} width='100%' height='auto' />
            </Box>
            <Typography
               sx={{
                  fontSize: '20px',
                  lineHeight: '24px',
                  fontWeight: 700,
               }}
            >
               {currentCharity?.title}
            </Typography>

            <Typography
               sx={{
                  color: '#666666',
                  fontSize: '16px',
                  lineHeight: '19px',
                  fontWeight: 500,
               }}
            >
               {currentCharity?.shortDescription}
            </Typography>
            <Typography
               sx={{
                  color: '#666666',
                  fontSize: '16px',
                  lineHeight: '19px',
                  fontWeight: 500,
                  marginTop: '20px',
               }}
            >
               {currentCharity?.description}
            </Typography>
         </Box>
      </ContentWrapper>
   );
};
