import { SvgIcon } from '@mui/material';
import React from 'react';

export function CheckedIcon() {
   return (
      <svg width='14' height='12' viewBox='0 0 16 13' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path d='M14 2L5.97031 11.5063L1.42676 7.50586' fill='none' />
         <path
            d='M14 2L5.97031 11.5063L1.42676 7.50586'
            stroke='#fff'
            strokeWidth='2.63'
            strokeLinecap='round'
            strokeLinejoin='round'
         />
      </svg>
   );
}

export function CloseIcon() {
   return (
      <svg width='15' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <line x1='0.707107' y1='1.29289' x2='12.7071' y2='13.2929' stroke='white' strokeWidth='2' />
         <line x1='12.7071' y1='1.70711' x2='0.707108' y2='13.7071' stroke='white' strokeWidth='2' />
      </svg>
   );
}

export function GoogleIcon() {
   return (
      <SvgIcon>
         <svg width='48' height='48' viewBox='0 0 48 48'>
            <path
               fill='#FFC107'
               d='M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12c0-6.627,5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24c0,11.045,8.955,20,20,20c11.045,0,20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z'
            />
            <path
               fill='#FF3D00'
               d='M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z'
            />
            <path
               fill='#4CAF50'
               d='M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36c-5.202,0-9.619-3.317-11.283-7.946l-6.522,5.025C9.505,39.556,16.227,44,24,44z'
            />
            <path
               fill='#1976D2'
               d='M43.611,20.083H42V20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571c0.001-0.001,0.002-0.001,0.003-0.002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z'
            />
         </svg>
      </SvgIcon>
   );
}

export function PlusIcon() {
   return (
      <svg
         xmlns='http://www.w3.org/2000/svg'
         viewBox='0 0 24 24'
         width='20'
         height='20'
         fill='none'
         stroke='#262627'
         strokeWidth='3'
         strokeLinecap='round'
         strokeLinejoin='round'
      >
         <line x1='12' y1='5' x2='12' y2='19' />
         <line x1='5' y1='12' x2='19' y2='12' />
      </svg>
   );
}

export function SumIcon() {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='27' height='27' viewBox='0 0 27 27' fill='none'>
         <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M13.3333 26.9987C20.6971 26.9987 26.6667 21.0292 26.6667 13.6654C26.6667 6.30157 20.6971 0.332031 13.3333 0.332031C5.96954 0.332031 0 6.30157 0 13.6654C0 21.0292 5.96954 26.9987 13.3333 26.9987ZM14.667 12.3294H18.667C19.0206 12.3294 19.3598 12.4699 19.6098 12.72C19.8598 12.97 20.0003 13.3091 20.0003 13.6628C20.0003 14.0164 19.8598 14.3555 19.6098 14.6056C19.3598 14.8556 19.0206 14.9961 18.667 14.9961H14.667V18.9961C14.667 19.3497 14.5265 19.6889 14.2765 19.9389C14.0264 20.189 13.6873 20.3294 13.3337 20.3294C12.98 20.3294 12.6409 20.189 12.3909 19.9389C12.1408 19.6889 12.0003 19.3497 12.0003 18.9961V14.9961H8.00033C7.6467 14.9961 7.30757 14.8556 7.05752 14.6056C6.80747 14.3555 6.66699 14.0164 6.66699 13.6628C6.66699 13.3091 6.80747 12.97 7.05752 12.72C7.30757 12.4699 7.6467 12.3294 8.00033 12.3294H12.0003V8.32943C12.0003 7.9758 12.1408 7.63667 12.3909 7.38662C12.6409 7.13657 12.98 6.99609 13.3337 6.99609C13.6873 6.99609 14.0264 7.13657 14.2765 7.38662C14.5265 7.63667 14.667 7.9758 14.667 8.32943V12.3294Z'
            fill='white'
            fillOpacity='0.8'
         />
      </svg>
   );
}

export function ShareIcon() {
   return (
      <svg xmlns='http://www.w3.org/2000/svg' width='25' height='25' fill='none' viewBox='0 0 25 25'>
         <path
            fill='#333'
            d='M3.18 21.664a.766.766 0 00.834-.591c.057-.25 1.433-5.905 8.422-6.967v2.963a.767.767 0 001.25.596l8.53-6.902a.767.767 0 000-1.191l-8.53-6.902a.767.767 0 00-1.249.596V6.36C6.11 6.763 2.5 12.015 2.5 20.903a.767.767 0 00.68.76z'
         />
      </svg>
   );
}

export function CheckMarks() {
   return (
      <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
         <path
            d='M5.91152 7.66721L12.0181 1.53797L12.1089 1.51527C12.5363 1.0878 13.1161 0.847656 13.7206 0.847656C14.3252 0.847656 14.9049 1.0878 15.3324 1.51527C15.7599 1.94274 16 2.5225 16 3.12703C16 3.73156 15.7599 4.31133 15.3324 4.7388L7.52328 12.4798C7.31225 12.6926 7.06117 12.8615 6.78454 12.9767C6.50791 13.092 6.2112 13.1513 5.91152 13.1513C5.61184 13.1513 5.31513 13.092 5.0385 12.9767C4.76186 12.8615 4.51079 12.6926 4.29976 12.4798L0.667613 8.84766C0.240147 8.42019 0 7.84042 0 7.2359C0 6.63137 0.240147 6.0516 0.667613 5.62413C1.09508 5.19666 1.67485 4.95651 2.27938 4.95651C2.8839 4.95651 3.46367 5.19666 3.89114 5.62413L5.91152 7.66721Z'
            fill='#0FBD00'
         />
      </svg>
   );
}
