import React, { useLayoutEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { routes } from 'routes/routes';
import { useAppDispatch } from 'store/hooks';
import { checkMagicLink } from 'store/auth/auth.slice';
import { LoadingIcon } from 'components/common';
import { ValidatorAnimation } from './ValidatorAnimation';

export function CreatorFlowEmailValidatedPage() {
   const { t } = useTranslation();
   const dispatch = useAppDispatch();
   const params = useParams();
   const navigate = useNavigate();
   const [isVerified, setIsVerified] = useState(false);

   useLayoutEffect(() => {
      if (params?.code) {
         dispatch(checkMagicLink(params?.code)).then(({ payload }) => {
            if (payload.response && payload.response.status === 400) {
               console.log('Error: ', payload.response);
            } else if (payload.user) {
               setIsVerified(true);
            }
         });
      }
   }, []);

   return (
      <Box
         sx={{
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
         }}
      >
         <Box
            sx={{
               width: '400px',
               display: 'flex',
               justifyContent: 'center',
            }}
         >
            <Box height='100%'>{isVerified ? <ValidatorAnimation /> : 'Loading....'} </Box>
         </Box>
      </Box>
   );
}
