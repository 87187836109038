import { Box } from '@mui/material';
import React from 'react';

function BonoLogo({ width = '60px' }: { width?: string }) {
   const useGlobalStyle = process.env.REACT_APP_BONO_GLOBAL === 'true';
   return (
      <Box sx={{ width, aspectRatio: '78/52' }}>
         {useGlobalStyle ? (
            <svg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 78 52'>
               <g clipPath='url(#clip0_6738_9416)'>
                  <path fill='#000' d='M77.5 2.87L73.612 0 .5 15.224v33.664L4.388 52 77.5 36.573V2.87z' />
                  <path fill='#fff' d='M5.633 19.109L76.255 4.404V35.56L5.633 50.463V19.109z' />
                  <path
                     fill='#000'
                     d='M75.01 5.938v28.61L6.875 48.925v-28.8L75.01 5.937zM77.5 2.871L4.387 18.093V52l73.112-15.427V2.87v.001z'
                  />
                  <path
                     fill='#000'
                     d='M12.38 24.367l6.334-1.226c2.398-.625 6.946-.722 6.802 2.942-.082 2.056-1.09 4.147-3.115 5.304 2.522-.024 4.018 1.77 3.967 4.17-.085 3.854-4.184 5.374-7.447 6.04-2.535.512-3.836.774-6.519 1.305-.132-6.178.096-12.357-.02-18.535h-.002zm6.183 5.756c1.385-.286 2.1-1.003 2.107-2.025.008-.993-.677-1.321-2.062-1.026l-.987.21a420.327 420.327 0 01-.018 3.04l.96-.2zm-.998 4.255c-.013 1.45-.019 2.173-.024 3.51L19 37.622c1.55-.283 2.327-.981 2.337-2.099.01-1.175-.81-1.71-2.312-1.424-.58.11-.872.167-1.462.28h.002zM64.79 13.98c5.439-1 7.593 3.55 7.593 7.819 0 5.309-2.065 9.733-7.683 10.923-5.163 1.095-8.143-2.855-8.033-7.743.113-5.025 2.804-10.02 8.124-10.998v-.002zm-.093 15.132c1.915-.358 3.195-2.782 3.226-6.501.032-3.624-1.194-5.443-3.129-5.02-1.935.422-3.263 2.762-3.299 6.324-.038 3.625 1.286 5.555 3.202 5.197zM34.72 19.76c5.438-1 7.593 3.55 7.593 7.819 0 5.309-2.065 9.733-7.684 10.924-5.162 1.094-8.142-2.856-8.032-7.744.113-5.025 2.803-10.02 8.124-10.998v-.001zm-.094 15.132c1.916-.358 3.195-2.782 3.227-6.501.031-3.623-1.195-5.443-3.13-5.02-1.934.422-3.262 2.762-3.298 6.324-.038 3.625 1.285 5.555 3.201 5.197zM43.052 18.413c1.669-.326 2.492-.489 4.129-.81l4.61 7.997c.012-2.97.034-5.941.017-8.91 1.612-.319 2.413-.478 4.017-.798.03 5.992-.047 11.983-.013 17.974-1.69.353-2.537.531-4.239.89l-4.422-7.54c-.016 2.828-.043 5.655-.026 8.483l-4.147.88c-.033-6.056.1-12.11.074-18.167v.001z'
                  />
               </g>
               <defs>
                  <clipPath id='clip0_6738_9416'>
                     <path fill='#fff' d='M0 0H77V52H0z' transform='translate(.5)' />
                  </clipPath>
               </defs>
            </svg>
         ) : (
            <svg viewBox='0 0 103 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
               <path d='M76.0234 40.6588L79.1028 23.168H83.3647L80.2853 40.6588H76.0234Z' fill='black' />
               <path
                  d='M71.0147 27.9492C74.1434 27.9492 75.9663 29.6983 75.5229 32.2603C75.1041 34.6499 72.887 36.128 69.8076 36.1527H68.1078C68.4527 36.9903 69.4134 37.5076 70.8176 37.5076C71.9016 37.5076 72.7145 37.3351 73.7985 36.9163L73.158 40.5623C72.1233 40.8826 71.064 41.0058 70.0786 41.0058C65.3979 41.0058 62.8852 38.4191 63.5996 34.3297C64.2647 30.5113 67.2702 27.9492 71.0147 27.9492ZM70.5713 31.1025C69.3395 31.1025 68.1817 32.3589 67.9107 33.9355H69.2656C70.5466 33.9355 71.5074 33.3443 71.6799 32.4328C71.803 31.6445 71.335 31.1025 70.5713 31.1025Z'
                  fill='black'
               />
               <path
                  d='M62.5984 28.2941L60.4306 40.6609H56.3904L56.8585 37.951C55.8731 39.9711 54.395 41.0058 52.4735 41.0058C49.5173 41.0058 47.9653 38.3698 48.655 34.4529C49.3448 30.5605 51.7837 27.9492 54.7645 27.9492C56.489 27.9492 57.5729 28.7868 57.9671 30.3881L58.3366 28.2941H62.5984ZM54.5428 37.483C55.8731 37.483 56.9324 36.3251 57.2527 34.5021L57.2773 34.4282C57.5729 32.6299 56.9078 31.472 55.6021 31.472C54.2718 31.472 53.2618 32.6299 52.9415 34.4529C52.5966 36.3251 53.2371 37.483 54.5428 37.483Z'
                  fill='black'
               />
               <path
                  d='M37.3481 40.6597L39.516 28.2929H43.7779L43.2605 31.1752C44.4677 29.0812 46.2414 27.9727 48.4832 27.9727L47.8426 31.6186C44.7386 31.6186 42.9649 32.8997 42.5215 35.3632L41.61 40.6597H37.3481Z'
                  fill='black'
               />
               <path
                  d='M32.0258 27.9492C33.6024 27.9492 35.3762 28.2448 36.534 28.7129L35.8689 32.4081C34.8835 31.8662 33.1097 31.472 31.8287 31.472C31.0404 31.472 30.5231 31.6937 30.4738 32.0879C30.3999 32.4821 30.868 32.753 32.0012 33.0487C35.2284 33.8123 36.2877 34.9209 35.8935 37.1134C35.4747 39.5769 33.0851 41.0058 29.4145 41.0058C27.8132 41.0058 26.1873 40.7101 24.9556 40.2174L25.6207 36.3744C26.7539 37.0395 28.7001 37.5569 29.9811 37.5569C30.9665 37.5569 31.5331 37.3105 31.607 36.8917C31.6809 36.4483 30.9911 36.2266 29.8087 35.9063C26.8525 35.1426 25.7932 33.9602 26.1627 31.8662C26.5815 29.4027 28.774 27.9492 32.0258 27.9492Z'
                  fill='black'
               />
               <path d='M18 40.6585L21.0301 23.4141H25.4151L22.385 40.6585H18Z' fill='black' />
               <path
                  d='M57.812 20.0634C58.5018 16.1465 61.5073 13.5352 65.3011 13.5352C69.1195 13.5352 71.1888 16.1465 70.4991 20.0634C69.8093 23.9804 66.8038 26.5917 63.01 26.5917C59.1916 26.5917 57.1223 23.9804 57.812 20.0634ZM62.0985 20.0634C61.7783 21.911 62.3695 23.0689 63.6259 23.0689C64.8823 23.0689 65.8923 21.911 66.2126 20.0634C66.5328 18.2404 65.9416 17.058 64.6852 17.058C63.4288 17.058 62.4188 18.2404 62.0985 20.0634Z'
                  fill='black'
               />
               <path
                  d='M46.5054 26.2417L48.6732 13.8749H52.9351L52.4178 16.7572C53.6249 14.6633 55.3986 13.5547 57.6404 13.5547L56.9999 17.2007C53.8959 17.2007 52.1221 18.4817 51.6787 20.9452L50.7672 26.2417H46.5054Z'
                  fill='black'
               />
               <path
                  d='M31.3472 26.2445L34.3773 9H40.8316C44.9457 9 47.1628 11.3157 46.5223 14.937C45.8818 18.583 42.8517 20.8987 38.7377 20.8987H36.6683L35.7322 26.2445H31.3472ZM37.3088 17.2527H38.9347C40.5114 17.2527 41.6692 16.3659 41.9156 14.937C42.1619 13.5328 41.3243 12.646 39.7477 12.646H38.1218L37.3088 17.2527Z'
                  fill='black'
               />
            </svg>
         )}
      </Box>
   );
}

export default BonoLogo;
