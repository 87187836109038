import React from 'react';
import { Modal, Box, Typography, Divider, IconButton } from '@mui/material';
import { Icon } from 'components/icon';
import { ENUM_ICON } from 'enums/icons';

import type { ModalWrapperProps } from './types';

export const ModalWrapper = ({ open, onClose, children, title }: ModalWrapperProps) => {
   return (
      <Modal open={open} onClose={onClose}>
         <Box
            component='div'
            sx={{
               background: ({ palette }) => palette.white.main,
               // border: ({ palette }) => `1px solid ${palette.black.main}`,
               borderRadius: ({ typography, size }) => typography.pxToRem(size.font_6),
               // boxShadow: ({ palette }) => `-4px 4px 0 ${palette.black.main}`,
               position: 'relative',
               top: '32%',
               margin: '0 auto',
               width: 'calc(100% - 60px)',
               maxWidth: '410px',
               // minHeight: ({ typography }) => typography.pxToRem(200),
            }}
         >
            <Box
               component='div'
               width='100%'
               display='flex'
               pt={1.875}
               pb={1.875}
               pl={3.75}
               pr={2.5}
               justifyContent='space-between'
               alignItems='center'
            >
               <Typography component='p' className='ModalTitle'>
                  {title}
               </Typography>
               <IconButton disableRipple onClick={onClose}>
                  <Icon name={ENUM_ICON.CLOSE_BLACK} />
               </IconButton>
            </Box>
            {children}
         </Box>
      </Modal>
   );
};
