import React from 'react';
import ReactDOM from 'react-dom/client';
import { ThemeProvider, CssBaseline } from '@mui/material';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import bonoStore, { persistor } from 'store';

import { bonoTheme } from 'styles/theme';

import './index.css';
import App from './App';
import './utils/i18n';
// trigger rebuild 12112023

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
   <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
      <BrowserRouter>
         <Provider store={bonoStore}>
            <PersistGate persistor={persistor} loading={null}>
               <ThemeProvider theme={bonoTheme}>
                  <CssBaseline />
                  <React.Suspense>
                     <App />
                  </React.Suspense>
               </ThemeProvider>
            </PersistGate>
         </Provider>
      </BrowserRouter>
   </GoogleOAuthProvider>
);
