import { Box, Typography } from '@mui/material';
import React from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import CheckIcon from '@mui/icons-material/Check';
import { useParams } from 'react-router-dom';
import { CauseTypes } from 'store/causes/types';

export function CausesSlide(props: { data: Array<CauseTypes>; isPublic: boolean }) {
   const slideStyle = { minWidth: '340px', flex: '1', alignItems: 'stretch', height: 'auto' };
   const backgroundColors = ['#CCD9D9', '#E4CCCC', '#E9E0D4'];
   const { isPublic } = props;

   return (
      <Box sx={{ mr: { xs: '0px', md: 0 } }}>
         <Box
            sx={{ display: { xs: 'block', md: 'none' }, width: { xs: 'calc(100dvw - 30px)', md: '100%' }, pb: '20px' }}
         >
            <Swiper
               watchSlidesProgress
               spaceBetween={0}
               breakpoints={{
                  '0': {
                     slidesPerView: window.innerWidth / 340,
                  },
                  '1': {
                     slidesPerView: window.innerWidth / 360,
                  },
               }}
               pagination={{
                  clickable: true,
               }}
               modules={[Pagination]}
               className='mySwiper'
               style={{
                  overflow: 'visible',
                  display: 'flex',
                  alignItems: 'stretch',
                  '--swiper-pagination-bottom': '-26px',
               }}
            >
               {props?.data
                  .sort((a: { order: number }, b: { order: number }) => a.order - b.order)
                  .map((cause, index) => {
                     const { id, title, shortDescription, description, icon, active, impactBackground, impactImage } =
                        cause;
                     return (
                        <React.Fragment key={`silde_${id}`}>
                           <SwiperSlide style={slideStyle}>
                              <CauseBox
                                 color={impactBackground}
                                 svgIcon={impactImage}
                                 title={title}
                                 text={shortDescription}
                                 isPublic={isPublic}
                              />
                           </SwiperSlide>
                        </React.Fragment>
                     );
                  })}
            </Swiper>
         </Box>
         <Box
            sx={{
               display: { xs: 'none', md: 'grid' },
               gridAutoRows: '1fr',
               gap: '10px',
               width: '100%',
               pb: '20px',
            }}
         >
            {props?.data
               .sort((a: { order: number }, b: { order: number }) => a.order - b.order)
               .map((cause, index) => {
                  const {
                     id,
                     title,
                     shortDescription,
                     description,
                     icon,
                     active,
                     order,
                     impactHeader,
                     impactBody,
                     impactBackground,
                     impactImage,
                     impactHashtag,
                  } = cause;
                  return (
                     <React.Fragment key={`desktop_${id}`}>
                        <CauseBox
                           color={impactBackground}
                           svgIcon={impactImage}
                           title={title}
                           text={shortDescription}
                           isPublic={isPublic}
                        />
                     </React.Fragment>
                  );
               })}
         </Box>
      </Box>
   );
}

function CauseBox(props: { title: string; color: string; text: string; svgIcon: string; isPublic: boolean }) {
   const { title, color, text, svgIcon, isPublic } = props;

   return (
      <Box sx={{ width: { xs: '340px', md: '100%' }, display: 'flex', height: '100%' }}>
         <Box
            sx={{
               backgroundColor: color || '#fff',
               borderRadius: '4px',
               position: 'relative',
               border: '2px solid rgba(0,0,0,0.3)',
               display: 'grid',
               gridTemplateColumns: '1fr 60px',
               alignItems: 'center',
               gap: '8px',
               p: '24px 24px 24px 16px',
               cursor: { xs: 'grab', sm: 'default' },
               mr: { xs: '15px', md: 0 },
               flex: '1',
            }}
         >
            <Box>
               {isPublic && (
                  <CheckIcon
                     sx={{
                        position: 'absolute',
                        top: '-8px',
                        right: '-8px',
                        background: 'white',
                        borderRadius: '50%',
                        backgroundColor: '#333333',
                        fill: 'white',
                        padding: '3px',
                     }}
                  />
               )}
               <Typography
                  sx={{
                     color: '#FFFFFF',
                     fontFamily: 'Inter',
                     fontWeight: '700',
                     fontSize: '20px',
                     lineHeight: '22px',
                     pb: '6px',
                  }}
               >
                  {title}
               </Typography>
               <Typography
                  sx={{
                     fontFamily: 'Inter',
                     fontWeight: '600',
                     fontSize: '14px',
                     lineHeight: '18px',
                     color: 'rgba(255,255,255,0.8)',
                  }}
               >
                  {text}
               </Typography>
            </Box>
            <Box sx={{ pr: '4px' }}>
               <img
                  // style={{ filter: 'invert(0%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(0%) contrast(100%)',}}
                  alt='cuase icon'
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(svgIcon)}`}
               />
            </Box>
         </Box>
      </Box>
   );
}
