import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText, Typography, Button, Box } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForward from '@mui/icons-material/ArrowForward';
import { useTranslation } from 'react-i18next';

interface WelcomeDrawerProps {}

function WelcomeDrawer() {
   const [searchParams, setSearchParams] = useSearchParams();
   const { t } = useTranslation();
   const [isOpen, setIsOpen] = React.useState((searchParams.get('welcome') && searchParams.get('welcome')) === 'true');
   const messages: string[] = t('gratitudePopUp.bulletsUp', { returnObjects: true });

   const handleClose = () => {
      setSearchParams({ welcome: 'false' });
      setIsOpen(false);
   };

   return (
      <dialog
         style={{
            position: 'fixed',
            top: '0px',
            bottom: '0px',
            left: '0px',
            right: '0px',
            zIndex: '1000',
            background: '#00000099',
         }}
         open={isOpen}
      >
         <Box
            sx={{
               width: '100dvw',
               height: '100dvh',
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
            }}
         >
            <Box
               sx={{
                  width: { xs: '100dvw', sm: '480px' },
                  background: '#fff',
                  border: '2px solid #333',
                  borderRadius: '8px',
                  padding: '40px',
                  position: 'relative',
               }}
            >
               <Typography
                  sx={{
                     fontWeight: '900',
                     fontSize: '34px',
                     lineHeight: '38px',
                     color: '#333',
                  }}
                  variant='h5'
               >
                  {t('gratitudePopUp.header').split(' ').slice(0, 3).join(' ')}
                  <br />
                  {t('gratitudePopUp.header').split(' ').slice(3, 5).join(' ')}
               </Typography>
               <Typography
                  sx={{
                     fontWeight: '700',
                     fontSize: '22px',
                     color: '#333',
                     mt: 3.75,
                     mb: 0.1025,
                     lineHeight: '26.63px',
                  }}
                  variant='h6'
               >
                  {t('gratitudePopUp.subHeading')}
               </Typography>
               <List
                  sx={{
                     padding: '0px',
                  }}
               >
                  {Array.isArray(messages) &&
                     messages.map((text) => {
                        return (
                           <ListItem
                              key={`message-${text}`}
                              sx={{
                                 p: 0,
                                 py: 0.75,
                              }}
                           >
                              <CheckIcon
                                 sx={{
                                    color: '#0FBD00',
                                    strokeWidth: 2,
                                    stroke: 'currentColor',
                                    marginRight: '10px',
                                    width: '16px',
                                    height: '16px',
                                 }}
                              />{' '}
                              <Typography
                                 sx={{
                                    fontWeight: '500',
                                    fontSize: '16',
                                    color: '#333',
                                    lineHeight: '19.36px',
                                 }}
                                 variant='body1'
                              >
                                 {text}
                              </Typography>
                           </ListItem>
                        );
                     })}
               </List>
               <Typography
                  sx={{
                     fontWeight: '500',
                     fontSize: '16',
                     color: '#333',
                     lineHeight: '19.36px',
                     mt: 3,
                  }}
                  variant='body1'
               >
                  {t('gratitudePopUp.preFooter')}
               </Typography>

               <Button
                  variant='contained'
                  color='black'
                  onClick={handleClose}
                  sx={{
                     fontWeight: '700',
                     fontSize: '18px',
                     color: '#333',
                     lineHeight: '21.78px',
                     mt: 3.75,
                     textAlign: 'center',
                     width: '100%',
                     borderRadius: '100px',
                     py: 1.625,
                     textTransform: 'none',
                  }}
               >
                  {t('gratitudePopUp.footer')}{' '}
                  <ArrowForward
                     sx={{
                        fontSize: '18px',
                        ml: 0.5,
                     }}
                  />
               </Button>
               <CloseIcon
                  onClick={handleClose}
                  sx={{
                     cursor: 'pointer',
                     color: '#000',
                     strokeWidth: 1,
                     stroke: 'currentColor',
                     position: 'absolute',
                     top: '16px',
                     right: '16px',
                  }}
               />
            </Box>
         </Box>
      </dialog>
   );
}

export default WelcomeDrawer;
