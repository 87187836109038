import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import { getSocialImapactIndicator } from './creators.services';
import type { CreatorsSliceTypes, CalculatedIdicatorsTypes, ICauseModalPayload } from './types';

const initialState: CreatorsSliceTypes = {
   currentStep: 1,
   backStep: 0,
   name: null,
   promocode: null,
   email: null,
   socialNetworks: [],
   followersCount: 0,
   viewsCount: 0,
   howMuchPosts: 0,
   creatorCoupon: null,
   selectedCausesList: [],
   calculatedIdicators: null,
   causesModal: {
      showModal: false,
      modalText: '',
   },
};

export const getSocialImapactIndicatorForUser = createAsyncThunk(
   'creator/getSocialImapactIndicator',
   async (_, { rejectWithValue, dispatch, getState }) => {
      const { signupData } = (getState() as RootState).auth;
      const { selectedCausesList } = (getState() as RootState).causes;
      try {
         const response = await getSocialImapactIndicator({
            amountOfPosts: signupData.details.howMuchPosts || 1,
            viewCount: signupData.details.viewsCount || 1000,
            followersCount: signupData.details.followersCount || 1000,
            socialNetworks: signupData.details.socialNetworks || [],
            cuases: selectedCausesList.map((causeId) => causeId.id),
         });
         dispatch(setCalculatedIdicators(response.data));
         return response.data;
      } catch (error) {
         if (error instanceof Error) {
            return rejectWithValue(error);
         }
         return error;
      }
   }
);

export const creatorsSlice = createSlice({
   name: 'creators',
   initialState,
   reducers: {
      resetStore() {
         return { ...initialState };
      },
      showCausesModal(state, action: PayloadAction<ICauseModalPayload>) {
         state.causesModal.showModal = true;
         state.causesModal.modalTitle = action.payload.title;
         state.causesModal.modalText = action.payload.text;
      },
      resetCausesModal(state) {
         state.causesModal.showModal = false;
         state.causesModal.modalTitle = undefined;
         state.causesModal.modalText = '';
      },
      setCalculatedIdicators(state, action: PayloadAction<CalculatedIdicatorsTypes[]>) {
         state.calculatedIdicators = action.payload;
      },
      clearCalculatedIdicators(state) {
         state.calculatedIdicators = null;
      },
      // setSelectedCause(state, action: PayloadAction<number>) {
      //    state.selectedCausesList?.push(action.payload);
      // },
      setSelectedCause(state, action: PayloadAction<number[]>) {
         state.selectedCausesList = action.payload;
      },
      updateCurrentStep(state, action: PayloadAction<number>) {
         state.currentStep = action.payload;
      },
      updateBackStep(state, action: PayloadAction<number>) {
         state.backStep = action.payload;
      },
      updateName(state, action: PayloadAction<string | null>) {
         state.name = action.payload;
      },
      updatePromocode(state, action: PayloadAction<string | null>) {
         state.promocode = action.payload;
      },
      updateEmail(state, action: PayloadAction<string | null>) {
         state.email = action.payload;
      },
      updateSocialNetworks(state, action: PayloadAction<string[]>) {
         state.socialNetworks = action.payload;
      },
      updateFollowersCount(state, action: PayloadAction<number>) {
         state.followersCount = action.payload;
      },
      updateViewsCount(state, action: PayloadAction<number>) {
         state.viewsCount = action.payload;
      },
      updateHowMuchPosts(state, action: PayloadAction<number>) {
         state.howMuchPosts = action.payload;
      },
      updateCreatorCoupon(state, action: PayloadAction<string | null>) {
         state.creatorCoupon = action.payload;
      },
   },
});

export const {
   setCalculatedIdicators,
   clearCalculatedIdicators,
   setSelectedCause,
   updateCurrentStep,
   updateBackStep,
   updateName,
   updatePromocode,
   updateEmail,
   updateSocialNetworks,
   updateFollowersCount,
   updateViewsCount,
   updateHowMuchPosts,
   updateCreatorCoupon,
   resetCausesModal,
   showCausesModal,
   resetStore,
} = creatorsSlice.actions;

export const creatorsState = (state: RootState) => state.creators;

export default creatorsSlice.reducer;
