import { Box, Button, Typography } from '@mui/material';

import React, { useEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { updateHowMuchPosts } from 'store/auth/auth.slice';
import { useAppDispatch } from 'store/hooks';

export interface StepComponentProps {
   getHeight: (height: number) => void;
}

export const colors = {
   button: '#B415FF',
} as const;

export function PostStep({ getHeight }: StepComponentProps): React.ReactElement {
   const [selectedValue, setSelectedValue] = useState<number>(0);
   const containerRef = useRef<HTMLDivElement | null>(null);
   const [totalHeight, setTotalHeight] = useState(0);
   const dispatch = useAppDispatch();

   useEffect(() => {
      if (containerRef.current) {
         const { height } = containerRef.current.getBoundingClientRect();
         setTotalHeight(height);
         getHeight(height);
      }
   }, []); // Runs once on mount.

   useEffect(() => {
      dispatch(updateHowMuchPosts(selectedValue));
   }, [selectedValue]); // Runs every time value changes.

   function renderButton(title: string, value: number, opacity: string) {
      return (
         <Button
            onClick={() => setSelectedValue(value)}
            sx={{
               width: '100%',
               height: '64px',
               borderRadius: '8px',
               textAlign: 'left',
               backgroundColor: `#B415FF${opacity}`,
               boxShadow: 'unset',
               color: '#333',
               fontWeight: '700',
               size: '18px',
               justifyContent: 'start',
               border: selectedValue === value ? '2px solid #333' : '1px solid #0000001A',
               '&:focus, &:focus-visible, &:hover': {
                  backgroundColor: `#B415FF${opacity}`,
                  border: 'solid 2px #333',
                  boxShadow: 'none',
               },
            }}
            variant='contained'
         >
            {title}
         </Button>
      );
   }

   return (
      <Box
         ref={containerRef}
         sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'start',
            paddingTop: '40px',
            gap: 3.75,
         }}
      >
         <Typography sx={{ fontWeight: 900, lineHeight: '38px' }} variant='h4' component='h1'>
            How much do you post about social issues?
         </Typography>
         <Box sx={{ width: '100%', gap: '10px', display: 'flex', flexDirection: 'column' }}>
            {renderButton('Not at all', 0, '08')}
            {renderButton('Every once in a while', 1, '0D')}
            {renderButton('Most of the time', 6, '14')}
            {renderButton('I’m obsessed!', 12, '1F')}
         </Box>
      </Box>
   );
}
